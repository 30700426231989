import { SearchData } from 'types/searchData'
import { APIErrorDeprecated } from 'api/client/errors'
import sendData from '../../utils/sendData'
import sendError from '../../utils/sendError'
import transformFlightDetails from '../../utils/transformFlightDetails'

export const click = (searchData: SearchData) => {
  sendData(
    'search_avia.search_history.click',
    transformFlightDetails(searchData)
  )
}

export const error = (error: APIErrorDeprecated) => {
  sendError('search_avia.search_history.error', error)
}
