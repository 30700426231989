import styled, { css } from 'styled-components/macro'
import { control } from '@kupibilet/ui/utils/reset'
import { theme } from 'components/ThemeProvider'
import { Props, Size } from './index'

const TYPOGRAPHY: Record<Size, number> = {
  small: 16,
  normal: 18,
  large: 20,
}

const SIZES: Record<Size, number> = {
  small: 12,
  normal: 15,
  large: 20,
}

const calculateButtonPadding = (
  size: Size,
  isIconOnly: boolean,
  hasLeftIcon: boolean,
  hasRightIcon: boolean
) => {
  const typographyRelatedPadding = (
    (SIZES[size] * 2 - TYPOGRAPHY[size]) /
    2
  ).toFixed(1)
  const iconVisualCenterShift = 5 / 4
  const iconPadding = ((SIZES[size] / 2) * iconVisualCenterShift).toFixed(1)

  if (isIconOnly) {
    return css`
      padding: ${typographyRelatedPadding}px;
    `
  }

  return css`
    padding: ${typographyRelatedPadding}px;
    padding-right: ${hasRightIcon ? iconPadding : SIZES[size]}px;
    padding-left: ${hasLeftIcon ? iconPadding : SIZES[size]}px;
  `
}

const calculateTextPadding = (
  size: Size,
  hasLeftIcon: boolean,
  hasRightIcon: boolean
) => {
  const iconVisualCenterShift = 3 / 4
  const iconPadding = ((SIZES[size] / 2) * iconVisualCenterShift).toFixed(1)

  return css`0 ${hasRightIcon ? iconPadding : 0}px 0 ${
    hasLeftIcon ? iconPadding : 0
  }px`
}

const calculateButtonBorderRadius = (
  size: Size,
  shape: NonNullable<Props['shape']>
) => {
  const radius = shape === 'default' ? 6 : SIZES[size]

  return css`
    border-radius: ${radius}px;
  `
}

const getButtonColor = (variant: Props['variant'], isDanger?: boolean) => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return theme.color.background
    default:
      return isDanger ? theme.color.fail : theme.color.primaryDarkest
  }
}

const getButtonHoverColor = (variant: Props['variant'], isDanger?: boolean) => {
  if (variant === 'link' || variant === 'shadow') {
    return theme.color[isDanger ? 'failLighter' : 'primaryDark']
  }
}

const getButtonBackground = (variant: Props['variant'], isDanger?: boolean) => {
  switch (variant) {
    case 'default':
      return theme.color.miscLightest
    case 'primary':
      return theme.color[isDanger ? 'fail' : 'primaryDark']
    case 'secondary':
      return theme.color[isDanger ? 'fail' : 'secondaryDark']
    case 'shadow':
      return theme.color.background
    case 'link':
      return 'transparent'
  }
}

const getButtonHoverBackground = (
  variant: Props['variant'],
  isDanger?: boolean
) => {
  switch (variant) {
    case 'default':
      return theme.color.miscLighter
    case 'primary':
      return theme.color[isDanger ? 'failDark' : 'primaryDarker']
    case 'secondary':
      return theme.color[isDanger ? 'failDark' : 'secondaryDarker']
    case 'link':
      return 'transparent'
  }
}

const getButtonActiveBackground = (
  variant: Props['variant'],
  isDanger?: boolean
) => {
  switch (variant) {
    case 'default':
      return theme.color.miscLight
    case 'primary':
      return theme.color[isDanger ? 'failDarker' : 'primaryDarkest']
    case 'secondary':
      return theme.color[isDanger ? 'failDarker' : 'secondaryDarkest']
    case 'link':
      return 'transparent'
  }
}

export const StyledButton = styled.button<
  Omit<Props, 'icon' | 'leftIcon' | 'rightIcon' | 'size' | 'shape'> &
    Required<Pick<Props, 'size' | 'shape'>> & {
      isIconOnly: boolean
      hasLeftIcon: boolean
      hasRightIcon: boolean
    }
>`
  ${control};
  ${({ theme }) => theme.transition};
  transition-property: opacity, box-shadow, color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ variant, isDanger }) => getButtonColor(variant, isDanger)};
  background: ${({ variant, isDanger }) =>
    getButtonBackground(variant, isDanger)};
  font-size: ${({ size }) => TYPOGRAPHY[size]}px;
  line-height: 1;
  transform: translateZ(0);
  cursor: pointer;

  ${({ disabled, isLoading }) =>
    (disabled || isLoading) &&
    css`
      opacity: 0.4;
      cursor: default;
    `}

  ${({ isBlock }) =>
    isBlock &&
    css`
      display: flex;
      width: 100%;
    `}
  
  ${({ variant, theme }) =>
    variant === 'shadow' &&
    css`
      box-shadow: 0 2px 4px 0 ${theme.color.miscLighter};
    `}

  ${({ size, isIconOnly, hasLeftIcon, hasRightIcon }) =>
    calculateButtonPadding(size, isIconOnly, hasLeftIcon, hasRightIcon)};

  ${({ size, shape }) => calculateButtonBorderRadius(size, shape)};

  .icon-inherit-color {
    fill: currentColor;
  }

  &:hover,
  &:focus {
    ${({ disabled, isLoading, variant, isDanger, theme }) =>
      !disabled &&
      !isLoading &&
      css`
        background: ${getButtonHoverBackground(variant, isDanger)};

        ${variant !== 'link' &&
        variant !== 'shadow' &&
        css`
          // Immediately change visual state on hover, mousedown and mouseup
          // Transition only for mouseleave
          transition: none;
        `}

        ${variant === 'shadow'
          ? css`
              box-shadow: 0 2px 4px 0 ${theme.color.miscLighter};
            `
          : css`
              box-shadow: 0 0 0 1px
                ${getButtonHoverBackground(variant, isDanger)};
            `}
      `}

    ${({ disabled, isLoading, variant, isDanger }) =>
      !disabled &&
      !isLoading &&
      css`
        color: ${getButtonHoverColor(variant, isDanger)};
      `}

  &:active {
    ${({ disabled, isLoading, variant, isDanger }) =>
      !disabled &&
      !isLoading &&
      css`
        background: ${getButtonActiveBackground(variant, isDanger)};

        ${variant !== 'shadow' &&
        css`
          box-shadow: none;
        `}
      `}
  }
`

export const StyledButtonText = styled.span<{
  size: Size
  hasLeftIcon: boolean
  hasRightIcon: boolean
}>`
  padding: ${({ size, hasLeftIcon, hasRightIcon }) =>
    calculateTextPadding(size, hasLeftIcon, hasRightIcon)};
`

export const IconWrap = styled.span<{ size: Size }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => TYPOGRAPHY[size]}px;
  height: ${({ size }) => TYPOGRAPHY[size]}px;
`
