import { APIErrorDeprecated } from 'api/client/errors'
import sendData from '../../../../utils/sendData'
import sendError from '../../../../utils/sendError'
import * as pageLoad from './pageLoad'

export { pageLoad }

type StepData = {
  inn: string
  businessAddress?: string
}

const transformData = ({ inn, businessAddress }: StepData) => {
  // Pick only these props
  return { inn, businessAddress }
}

export const complete = (data: StepData) => {
  sendData('account.register.step1.complete', transformData(data))
}

export const error = (error: APIErrorDeprecated | string, data: StepData) => {
  sendError('account.register.step1.error', error, transformData(data))
}
