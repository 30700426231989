import { APIErrorDeprecated } from 'api/client/errors'
import pick from 'lodash/pick'
import sendData from '../../../../utils/sendData'
import sendError from '../../../../utils/sendError'
import * as pageLoad from './pageLoad'
import { StepDataOnFinish } from './types'

export { pageLoad }

type Data = Omit<StepDataOnFinish, 'manager'> & {
  generalDirector: StepDataOnFinish['manager']
}

const transformData = (source: StepDataOnFinish): Data => {
  return {
    ...pick(source, [
      'inn',
      'kpp',
      'companyName',
      'businessAddress',
      'postalAddress',
    ]),
    generalDirector: source.manager,
  }
}

const transformError = (error: APIErrorDeprecated): APIErrorDeprecated => {
  return {
    ...error,
    details: error.details.map(({ target, ...rest }) => ({
      target: target === 'manager' ? 'general_director' : target,
      ...rest,
    })),
  }
}

export const complete = (data: StepDataOnFinish) => {
  sendData('account.register.step2.complete', transformData(data))
}

export const error = (error: APIErrorDeprecated, data: StepDataOnFinish) => {
  sendError(
    'account.register.step2.error',
    transformError(error),
    transformData(data)
  )
}
