import { APIErrorDeprecated } from 'api/client/errors'
import { APIError } from 'api/client/client'
import { SaveEmployeePayload } from 'api/employees'
import { EmployeeSource } from 'types/employeeSource'
import { removeEmptyPropsFromObject } from 'utils/removeEmptyPropsFromObject'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'

const fieldsMap = {
  lastNameRu: 'last_name',
  firstNameRu: 'first_name',
  middleNameRu: 'middle_name',
  lastNameEn: 'last_name_latin',
  firstNameEn: 'first_name_latin',
  birthdate: 'birthday',
  gender: 'sex',
  countryCode: 'citizenship',
  email: 'email',
  phoneNumber: 'phone_number',
} as const

type valueof<T> = T[keyof T]

type Data = {
  source: EmployeeSource
  employeeId: string
  fieldsAdded: valueof<typeof fieldsMap>[]
}

export const complete = (
  source: EmployeeSource,
  employeeId: string,
  values: SaveEmployeePayload
) => {
  const data: Data = {
    source,
    employeeId,
    fieldsAdded: Object.keys(removeEmptyPropsFromObject(values))
      .filter((value) => value !== 'skipMiddleName')
      .map(
        (value) =>
          fieldsMap[value as keyof Omit<SaveEmployeePayload, 'skipMiddleName'>]
      ),
  }

  sendData('employees.card.adding.complete', data)
}

export const error = (
  error: APIErrorDeprecated | APIError,
  source: EmployeeSource
) => {
  sendError('employees.card.adding.error', error, { source })
}
