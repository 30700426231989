import { FunctionComponent } from 'react'
import UIIcon from '@kupibilet/ui/components/Icon'

export type Props = {
  name: string
  size?: 'normal' | 'xxsmall' | 'xsmall' | 'medium' | 'xxlarge'
  fill?:
    | 'success'
    | 'fail'
    | 'background'
    | 'textLight'
    | 'textDark'
    | 'miscLightest'
    | 'miscDark'
    | 'miscDarker'
    | 'miscDarkest'
    | 'primaryLight'
    | 'primaryDarkest'
    | 'secondary'
    | 'secondaryDarker'
    | 'secondaryDarkest'
  rotate?: boolean
  striked?: boolean
  paid?: boolean
  inheritColor?: boolean
  className?: string
}

const Icon: FunctionComponent<Props> = ({ size = 'normal', ...props }) => {
  return <UIIcon size={size} {...props} />
}

export default Icon
