import { VoidFunctionComponent } from 'react'
import { ReactComponent as LogoImage } from './assets/logo.svg'
import { LinkStyled } from './styled'

type Props = {
  height?: number
}

const Logo: VoidFunctionComponent<Props> = ({ height = 30 }) => {
  return (
    <LinkStyled to="/">
      <LogoImage height={height} title="На главную" />
    </LinkStyled>
  )
}

export default Logo
