import isEqual from 'lodash/isEqual'
import { FormikContextType } from 'formik'
import { APIErrorDeprecated } from 'api/client/errors'
import { APIError } from '../../api/client/client'

export const handleGetErrors = <Values>(
  current: FormikContextType<Values>,
  prev: FormikContextType<Values> | null,
  error: APIErrorDeprecated | APIError | string | null | undefined,
  callback: (clientError: APIErrorDeprecated) => void
): void => {
  if (
    !Object.keys(current.errors).length ||
    isEqual(current.errors, prev?.errors) ||
    error
  ) {
    return
  }

  const errors = current.errors as Record<string, string>

  callback({
    status: 422,
    code: 'client_error',
    message: 'client error',
    text: 'Ошибка валидации',
    details: Object.entries(errors).map(([target, message = 'Ошибка']) => ({
      code: 'client_error',
      args: {},
      target,
      message: message,
    })),
    fieldErrors: errors,
  })
}
