import { FunctionComponent } from 'react'
import normalize from 'styled-normalize'
import {
  createGlobalStyle,
  ThemeProvider as ThemeProviderStyled,
} from 'styled-components/macro'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  ${normalize};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    ${theme.font};
    background-color: ${theme.color.background};
    color: ${theme.color.textDarker};
  }

  a {
    text-decoration: none;
  }

  svg {
    display: block;
    max-width: 100%;
  }
`

const ThemeProvider: FunctionComponent = ({ children }) => {
  return (
    <ThemeProviderStyled theme={theme}>
      <GlobalStyle />

      {children}
    </ThemeProviderStyled>
  )
}

export { default as theme } from './theme'

export default ThemeProvider
