/*
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import homeAirportReducer from '@kupibilet/forms/es/HomeAirport/ducks'
import { combineReducers } from 'redux'

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createReducer = () =>
  combineReducers({
    homeAirport: homeAirportReducer,
  })

export default createReducer
