import styled, { css } from 'styled-components/macro'
import UILink from '@kupibilet/ui/components/Link'

export const Link = styled(UILink)`
  font-size: inherit;
  line-height: inherit;
  word-break: break-word;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.miscDark};
      pointer-events: none;

      &:hover {
        color: ${theme.color.miscDark};
      }
    `}
`
