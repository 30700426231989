import styled, { css } from 'styled-components/macro'
import { theme } from 'components/ThemeProvider'
import { Types } from './index'

const COLORS: Record<Types, string> = {
  error: theme.color.fail,
  warning: theme.color.secondaryDark,
  info: '#004c80',
}

const BORDER_COLORS: Record<Types, string> = {
  error: '#f5b2a3',
  warning: '#ffd399',
  info: theme.color.primaryLight,
}

const BACKGROUNDS: Record<Types, string> = {
  error: '#fcece8',
  warning: '#fff4e5',
  info: theme.color.primaryLightest,
}

export const AlertWrap = styled.div<{ type: Types }>`
  ${({ theme }) => theme.borderRadiusLg};
  padding: 12px 16px;
  border: 1px solid;
  display: flex;

  ${({ type }) => css`
    color: ${COLORS[type]};
    border-color: ${BORDER_COLORS[type]};
    background: ${BACKGROUNDS[type]};
  `}
`

export const IconWrap = styled.div`
  display: flex;
  flex: none;
`

export const TextWrap = styled.div`
  margin-left: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
