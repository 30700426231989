import { APIErrorDeprecated } from 'api/client/errors'
import { Invoice } from 'components/ButtonAddBalance'
import sendData from '../../../../utils/sendData'
import sendError from '../../../../utils/sendError'

export const complete = (data: Invoice) => {
  sendData('account.finance.add_funds.form_invoice.complete', data)
}

export const error = (error: APIErrorDeprecated, data: Invoice) => {
  sendError('account.finance.add_funds.form_invoice.error', error, data)
}
