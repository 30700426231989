import { SearchData } from 'types/searchData'
import { Filters } from 'pages/search/Results/search/filters'
import {
  Endpoint,
  Transfer,
} from 'pages/search/Results/search/filters/airports'
import { ArrivalDepartureTime } from 'pages/search/Results/search/filters/arrivalDepartureTime'
import transformFlightDetails, {
  FlightDetails,
} from '../../utils/transformFlightDetails'
import sendData from '../../utils/sendData'

const uiSortOptionToAnalytics = {
  cheapest: 'cheapest',
  fastest: 'fastest',
  earlierDeparture: 'earliest_departure',
  earlierArrival: 'earliest_arrival',
  shortestTransfer: 'less_transfer_time',
} as const

type UISortOption = keyof typeof uiSortOptionToAnalytics

type AnalyticsSortingType = typeof uiSortOptionToAnalytics[UISortOption]

type Source = 'serp' | 'filters'

type ArrivalDepartureBack = {
  departureBackToTimeMin?: string
  departureBackToTimeMax?: string
  arrivalBackToTimeMin?: string
  arrivalBackToTimeMax?: string
}

type FilterParams = ArrivalDepartureBack & {
  baggage: boolean // baggageOnly
  transfers: number[] // transfersCount
  transferTimeMin: number
  transferTimeMax: number
  isAirportChange: boolean
  priceMin: number // amountMin
  priceMax: number // amountMax
  departureToTimeMin: string
  departureToTimeMax: string
  arrivalToTimeMin: string
  arrivalToTimeMax: string
  flightTimeMin: number
  flightTimeMax: number
  airlines: string[]
  airportsDeparture: string[]
  airportsArrival: string[]
  airportsTransfer: string[]
}

type Data = FlightDetails &
  FilterParams & {
    source: Source
    sortingType: AnalyticsSortingType
  }

const transformFilterParams = (filters: Filters): FilterParams => {
  type SelectableValue<Value> = {
    isSelected: boolean
    value: Value
  }

  const getSelectedValues = <Value>(options: SelectableValue<Value>[]) => {
    return options
      .filter(({ isSelected }) => isSelected)
      .map(({ value }) => value)
  }

  const getTransferTime = (duration?: number) => {
    return duration ? duration / 60 : 0
  }

  const getAirportCodes = ({ airports }: Endpoint) => {
    return airports
      .filter(({ isSelected }) => isSelected)
      .map(({ code }) => code)
  }

  const getTransferAirportCodes = (transfers: Transfer[]) => {
    return transfers
      .filter(({ isSelected }) => isSelected)
      .map(({ airportsCodes }) => airportsCodes)
      .flat()
  }

  const getArrivalDepartureBack = (
    flights: ArrivalDepartureTime[]
  ): ArrivalDepartureBack => {
    if (flights.length < 2) {
      return {}
    }

    const { departure, arrival } = flights[flights.length - 1]

    return {
      departureBackToTimeMin: departure.from,
      departureBackToTimeMax: departure.to,
      arrivalBackToTimeMin: arrival.from,
      arrivalBackToTimeMax: arrival.to,
    }
  }

  return {
    baggage: filters.baggageOnly,
    transfers: getSelectedValues(filters.transfers.count),
    transferTimeMin: getTransferTime(filters.transferDuration?.from),
    transferTimeMax: getTransferTime(filters.transferDuration?.to),
    isAirportChange: filters.transfers.isAirportChangeSelected,
    priceMin: filters.amount?.from ?? 0,
    priceMax: filters.amount?.to ?? 0,
    departureToTimeMin: filters.arrivalDepartureTime[0]?.departure.from ?? 0,
    departureToTimeMax: filters.arrivalDepartureTime[0]?.departure.to ?? 0,
    arrivalToTimeMin: filters.arrivalDepartureTime[0]?.arrival.from ?? 0,
    arrivalToTimeMax: filters.arrivalDepartureTime[0]?.arrival.to ?? 0,
    ...getArrivalDepartureBack(filters.arrivalDepartureTime),
    flightTimeMin: filters.flightDuration?.min ?? 0,
    flightTimeMax: filters.flightDuration?.to ?? 0,
    airlines: getSelectedValues(filters.airlines),
    airportsDeparture: filters.airports.endpoints.length
      ? getAirportCodes(filters.airports.endpoints[0])
      : [],
    airportsArrival: filters.airports.endpoints.length
      ? getAirportCodes(filters.airports.endpoints[1])
      : [],
    airportsTransfer: getTransferAirportCodes(filters.airports.transfers),
  }
}

export const complete = (
  source: Source,
  sortType: UISortOption,
  searchData: SearchData,
  filters: Filters
) => {
  const data: Data = {
    source,
    sortingType: uiSortOptionToAnalytics[sortType],
    ...transformFlightDetails(searchData),
    ...transformFilterParams(filters),
  }

  sendData('serp_avia.filter&sort.complete', data)
}
