import { VoidFunctionComponent } from 'react'
import { APIErrorDeprecated } from 'api/client/errors'
import { isFormValidationError } from 'api/client/errors'
import Alert from 'components/Alert'
import { FormRow } from 'components/FormRow/styled'

type Messages = Record<string, string | JSX.Element>

type Props = {
  error?: string | APIErrorDeprecated | null
  defaultMsg?: string | JSX.Element
  // code -> message
  customMessages?: Messages
}

const EMPTY_MESSAGES: Messages = {}

const FormError: VoidFunctionComponent<Props> = ({
  error = null,
  defaultMsg = 'Что-то пошло не так',
  customMessages = EMPTY_MESSAGES,
}) => {
  const hasValidationError =
    typeof error !== 'string' && isFormValidationError(error)

  if (!error || hasValidationError) {
    return null
  }

  const message =
    typeof error === 'string'
      ? error
      : customMessages[error.code] || error.text || defaultMsg

  return (
    <FormRow>
      <Alert>{message}</Alert>
    </FormRow>
  )
}

export default FormError
