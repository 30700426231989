import { FunctionComponent, ReactNode } from 'react'
import Tooltip from 'components/Tooltip'
import './style.css'

type Props = {
  content: ReactNode
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'leftTop'
    | 'topRight'
    | 'rightTop'
    | 'bottomLeft'
    | 'leftBottom'
    | 'bottomRight'
    | 'rightBottom'
  trigger?: 'hover' | 'click'
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}

const Popover: FunctionComponent<Props> = ({
  children,
  content,
  placement,
  trigger,
  visible,
  onVisibleChange,
}) => {
  return (
    <Tooltip
      title={content}
      placement={placement}
      trigger={trigger}
      visible={visible}
      onVisibleChange={onVisibleChange}
      overlayClassName="popover"
    >
      {children}
    </Tooltip>
  )
}

export default Popover
