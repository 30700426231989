import amplitude from 'amplitude-js'
import { decamelizeKeys } from 'humps'
import { APIErrorDeprecated } from 'api/client/errors'
import { APIError } from 'api/client/client'
import transformError from './transformError'

const sendError = (
  eventType: string,
  error: APIErrorDeprecated | APIError | string,
  eventProperties: object = {}
) => {
  amplitude.getInstance().logEvent(
    eventType,
    decamelizeKeys({
      ...eventProperties,
      ...transformError(error),
    })
  )
}

export default sendError
