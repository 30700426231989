import styled, { css } from 'styled-components/macro'
import media from 'utils/media'

export const StyledContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  ${media.up.sm(css`
    max-width: 540px;
  `)}

  ${media.up.md(css`
    max-width: 720px;
  `)}

  ${media.up.lg(css`
    max-width: 960px;
  `)}

  ${media.up.xl(css`
    max-width: 1140px;
  `)}
`
