import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import analytics from 'analytics'
import { useSignOut } from 'api/auth'
import Auth from 'contexts/auth'
import { LinkStyled } from '../styled'

const LinkSignOut: FunctionComponent = ({ children }) => {
  const auth = Auth.useContainer()
  const { push } = useHistory()

  const [signOut] = useSignOut()

  const handleClick = () => {
    // TODO: memory leak при логауте
    signOut()

    // Всегда разлогиниваем пользователя, не дожидаясь ответа от API
    auth.signOut()
    push('/sign-in')

    analytics.account.logout.complete()
  }

  return (
    <LinkStyled onClick={handleClick} data-test-id="headerSignOutLink">
      {children}
    </LinkStyled>
  )
}

export default LinkSignOut
