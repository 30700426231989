import { APIErrorDeprecated } from 'api/client/errors'
import setUserIdAndProperties, {
  IdAndProperties,
} from '../../../utils/setUserIdAndProperties'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'
import * as pageLoad from './pageLoad'

export { pageLoad }

export const complete = (properties?: IdAndProperties) => {
  if (properties) {
    setUserIdAndProperties(properties)
  }

  sendData('account.login.complete')
}

export const error = (error: APIErrorDeprecated | string) => {
  sendError('account.login.error', error)
}
