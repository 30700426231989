import { OrderDocument, OrderDocumentType } from 'types/api/orderDocument'
import sendData from '../../../utils/sendData'

type Data = {
  destination: 'email' | 'device'
  documents: OrderDocumentType[]
}

export const documentShare = (
  document: OrderDocument,
  destination: 'email' | 'device'
) => {
  const data: Data = {
    destination,
    documents: [document.type],
  }

  sendData('order_history.order.accounting_doсs.document_share', data)
}
