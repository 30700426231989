import styled from 'styled-components/macro'
import { Link } from 'components/Link/styled'

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.miscLightest};
  margin-right: 16px;
`

export const DropdownContentStyled = styled.div`
  margin: -6px -18px;
`

export const LinkStyled = styled(Link)`
  display: block;
  white-space: nowrap;
  padding: 6px 18px;
`
