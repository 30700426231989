import isPlainObject from 'lodash/isPlainObject'

export const removeEmptyPropsFromObject = <T>(item: T): Partial<T> => {
  const entries = Object.entries(item)
    .filter(([, val]) => val)
    .map(([key, val]) => {
      let value = val

      if (isPlainObject(val)) {
        value = removeEmptyPropsFromObject(val)
      }

      if (Array.isArray(val)) {
        value = val.map((arrItem) =>
          isPlainObject(arrItem) ? removeEmptyPropsFromObject(arrItem) : arrItem
        )
      }

      return [key, value]
    })

  return Object.fromEntries(entries)
}
