import amplitude from 'amplitude-js'

const analyticsId = B2B_APP_CONFIG.AMPLITUDE_ID

const initAnalytics = () => {
  if (!analyticsId) return
  amplitude.getInstance().init(analyticsId)
}

export default initAnalytics
