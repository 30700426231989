import { FunctionComponent, ReactNode } from 'react'
import { DescriptionWrap, LabelWrap } from './styled'

type Props = {
  name: string
  label: string
  labelRight?: ReactNode
  description?: string
}

const FormWrap: FunctionComponent<Props> = ({
  name,
  label,
  labelRight,
  description,
  children,
}) => {
  return (
    <>
      <LabelWrap>
        <label htmlFor={name}>{label}</label>
        {labelRight}
      </LabelWrap>

      {children}

      {description && (
        <DescriptionWrap>
          <label htmlFor={name}>{description}</label>
        </DescriptionWrap>
      )}
    </>
  )
}

export default FormWrap
