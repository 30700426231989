export type StorageWrapper = Pick<
  Storage,
  'getItem' | 'removeItem' | 'setItem' | 'clear'
> & {
  isKeyEquals: (fullKeyToCheck: string | null, key: string) => boolean
}

const createStorage = (keyPrefix: string): StorageWrapper => {
  const separator = '::'

  const getKey = (key: string) => `${keyPrefix}${separator}${key}`

  const isStorageKey = (prefixedKey: string) =>
    prefixedKey.startsWith(`${keyPrefix}${separator}`)

  return {
    isKeyEquals: (fullKeyToCheck: string | null, key: string) => {
      return fullKeyToCheck === getKey(key)
    },

    getItem: (key: string) => {
      return localStorage.getItem(getKey(key))
    },

    removeItem: (key: string) => {
      return localStorage.removeItem(getKey(key))
    },

    setItem: (key: string, value: string) => {
      return localStorage.setItem(getKey(key), value)
    },

    clear: () => {
      Object.keys(localStorage).forEach((key) => {
        if (isStorageKey(key)) {
          localStorage.removeItem(key)
        }
      })
    },
  }
}

export default createStorage
