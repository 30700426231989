import styled, { css } from 'styled-components/macro'
import media from 'utils/media'

type Size =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 'auto'
  | 'default'
  | number

type Props = {
  xs?: Size
  sm?: Size
  md?: Size
  lg?: Size
  xl?: Size
  textAlign?: 'left' | 'center' | 'right'
  alignSelf?: 'flex-start' | 'flex-end'
}

const getColCss = (size?: Size) => {
  if (!size) {
    return css``
  }

  if (size === 'default') {
    return css`
      flex: 1 0 0;
    `
  }

  const width =
    size === 'auto'
      ? 'auto'
      : size > 12
      ? `${size}px`
      : `${((size * 100) / 12).toFixed(6)}%`

  return css`
    flex: 0 0 auto;
    width: ${width};
  `
}

const Col = styled.div<Props>`
  width: 100%;
  max-width: 100%;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}

  ${({ xs, sm, md, lg, xl }) => css`
    ${!(xs || sm || md || lg || xl) &&
    css`
      flex: 1 0 0;
    `}

    ${getColCss(xs)};
    ${media.up.sm(getColCss(sm))}
    ${media.up.md(getColCss(md))}
    ${media.up.lg(getColCss(lg))}
    ${media.up.xl(getColCss(xl))}
  `};
`

export default Col
