import { AxiosError } from 'axios'
import { camelize } from 'humps'
import plural from 'plural-ru'
import { APIError } from './client'

export type ErrorResponse = {
  error: {
    code: string
    details: ErrorDetails[]
    message: string
  }
}

export type ErrorDetails = {
  code: string
  args: Args
  message: string
  target: string
}

type Args = {
  value?: number[]
  equal?: number[] | string[]
  min?: number[]
  max?: number[]
  type?: any[]
  list?: string[]
  attributes?: string[]
}

/**
 * @deprecated Тип ошибки для старого АПИ клиента. В новом клиенте ошибка наследуется от Error и содержит респонс с бэка без доп. трансформаций. Все трансформации нужно переносить в места, где они будут использоваться (аналитика, компонент для работы с формами, отдельные компоненты), т.к. если делать это в одном месте (здесь) структура ошибки будет очень сложной
 */
export type APIErrorDeprecated = ErrorResponse['error'] & {
  status: number
  text: string | null
  fieldErrors: {
    [key: string]: string
  }
}

export const isUnauthorizedError = (error: APIErrorDeprecated) => {
  return error.status === 401
}

export const isNotFoundError = (
  error?: APIErrorDeprecated | APIError | null
) => {
  if (error instanceof APIError) {
    return error.httpStatus === 404
  }

  return error?.status === 404
}

const isClientError = (
  error?: APIErrorDeprecated | null
): error is APIErrorDeprecated => {
  return error?.status === 422
}

export const isFormValidationError = (error?: APIErrorDeprecated | null) => {
  return isClientError(error) && Object.keys(error.fieldErrors).length > 0
}

type Transformers = {
  [key: string]: (args?: Args) => string
}

const errorTransformers: Transformers = {
  missing: () => 'Обязательное поле',
  stringRequired: () => 'Требуется строка',
  integerRequired: () => 'Требуется число',
  objectRequired: () => 'Требуется объект',
  arrayRequired: () => 'Требуется массив',
  decimal: () => 'Требуется десятичное число',
  taken: () => 'Уже занято',
  mustBeGreaterThan: (args) => {
    return args?.value
      ? `Должно быть больше, чем ${args.value[0]}`
      : 'Поле заполнено неверно'
  },
  wrongSize: (args) => {
    const getPluralValue = (value: number, isTwoForms?: boolean) => {
      return isTwoForms
        ? plural(value, '%d знака', '%d знаков')
        : plural(value, '%d знак', '%d знака', '%d знаков')
    }

    if (args?.equal) {
      const arg = args.equal[0]

      if (typeof arg === 'string' && arg.includes('..')) {
        const [from] = arg.split('..')
        return `Поле должно содержать не менее ${getPluralValue(
          Number(from),
          true
        )}`
      }

      return `Поле должно содержать ${getPluralValue(Number(arg))}`
    }

    if (args?.min) {
      return `Минимум ${getPluralValue(args.min[0])}`
    }

    if (args?.max) {
      return `Максимум ${getPluralValue(args.max[0])}`
    }

    return 'Поле заполнено неверно'
  },
  weakPassword: () => 'Слабый пароль',
  badEmail: () => 'Неверный формат email',
  mustBeUuid: () => 'Должно содержать uuid',
  mustBePhone: () => 'Неверный формат телефона',
  mustBeRussian: () => 'Должно содержать русские символы',
  mustBeValidRussianName: () => 'Должно содержать русские символы',
  mustBeLatin: () => 'Должно содержать английские символы',
  mustBeValidLatinName: () => 'Должно содержать английские символы',
  mustByTypeOf: (args) => {
    return args?.type
      ? `Значение должно быть типа: ${JSON.stringify(args.type[0])}`
      : 'Поле заполнено неверно'
  },
  wrongFormat: () => 'Неверный формат',
  badDate: () => 'Неверная дата',
  mustBeIncludedIn: (args) => {
    return args?.list
      ? `Значение должно быть одним из: ${args.list.join(', ')}`
      : 'Поле заполнено неверно'
  },
  mustBeFilled: () => 'Обязательное поле',
  accountBalanceInsufficient: () => 'Недостаточно средств на счёте',
  alreadyCommitted: () => 'Операция уже выполнена',
  destructionNotAllowed: () => 'Удаление запрещено',
  emailAlreadyConfirmed: () => 'Электронная почта уже подтверждена',
  emailUnconfirmed: () => 'Электронная почта не подтверждена',
  invalidCredentials: () => 'Неверные учётные данные',
  notFound: () => 'Не найдено',
  tokenExpired: () => 'Срок действия токена истек',
  tokenInvalid: () => 'Неверный токен',
  tokenUsed: () => 'Токен уже использован',
  unauthenticated: () => 'Необходимо пройти аутентификацию',
  atLeastOneRequired: (args) => {
    return args?.attributes
      ? `Должно быть хотя бы одно из следующих значений: ${args.attributes.join(
          ', '
        )}`
      : 'Поле заполнено неверно'
  },
  unknownCompany: () => 'Компания с указанным ИНН не найдена',
  invalid: () => 'Что-то пошло не так',
}

const getErrorFromTransformer = (
  code?: string,
  args: Args = {}
): string | null => {
  const errorTransformer = code ? errorTransformers[camelize(code)] : null
  return errorTransformer ? errorTransformer(args) : null
}

type FieldErrors = {
  [key: string]: string
}

export const getFieldErrors = (
  fieldValidationErrors: ErrorDetails[]
): FieldErrors => {
  return fieldValidationErrors.reduce((formErrors, { target, code, args }) => {
    const field = camelize(target)

    return {
      ...formErrors,
      [field]: getErrorFromTransformer(code, args) || 'Поле заполнено неверно',
    }
  }, {})
}

export const transformError = (
  errorResp: AxiosError<ErrorResponse>
): APIErrorDeprecated => {
  const { status, data } = errorResp?.response || {}

  if (!status || !data) {
    return {
      status: 500,
      text: 'Ошибка сервера',
      fieldErrors: {},
      code: 'internal_server_error',
      message: 'internal server error',
      details: [],
    }
  }

  const { error } = data

  const text = getErrorFromTransformer(error.code)
  const fieldErrors = getFieldErrors(error.details)

  return { status, text, fieldErrors, ...error }
}
