type Link = {
  to: string
  dataAttr: string
  text: string
}

export const MENU_ITEMS: Link[] = [
  {
    to: '/',
    dataAttr: 'headerTicketsLink',
    text: 'Авиабилеты',
  },
  {
    to: '/orders',
    dataAttr: 'headerOrdersLink',
    text: 'Поездки',
  },
  {
    to: '/employees',
    dataAttr: 'headerEmployeesLink',
    text: 'Сотрудники',
  },
]
