import {
  FunctionComponent,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import UITooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import './style.css'

type Props = {
  title: ReactNode
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'leftTop'
    | 'topRight'
    | 'rightTop'
    | 'bottomLeft'
    | 'leftBottom'
    | 'bottomRight'
    | 'rightBottom'
  overlayClassName?: string
  trigger?: 'hover' | 'click'
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}

const Tooltip: FunctionComponent<Props> = ({
  children,
  title,
  placement = 'top',
  trigger,
  visible,
  onVisibleChange,
  overlayClassName = 'tooltip',
}) => {
  const [container, setContainer] = useState<HTMLElement>()

  useEffect(() => {
    const elem = document.getElementById('portal')

    if (elem) {
      setContainer(elem)
    }
  }, [])

  const [innerVisible, setInnerVisible] = useState(false)

  const onVisibleInnerChange = (visible: boolean) => {
    setInnerVisible(visible)
  }

  if (!title || !container) {
    return <>{children}</>
  }

  return (
    <UITooltip
      overlay={title}
      placement={placement}
      getTooltipContainer={() => container}
      trigger={trigger}
      visible={visible ?? innerVisible}
      onVisibleChange={onVisibleChange ?? onVisibleInnerChange}
      transitionName="fade"
      mouseEnterDelay={0.1}
      overlayClassName={overlayClassName}
    >
      {isValidElement(children) ? children : <span>{children}</span>}
    </UITooltip>
  )
}

export default Tooltip
