import { Part, Segment, Ticket } from 'types/ticket'
import { Upsell } from 'types/api/ticket'
import checkIsRoundTrip from './checkIsRoundTrip'

type FlightType = 'regular' | 'split' | 'smart' | 'complex'

export type TicketDetails = {
  flightType: FlightType
  segments: {
    segmentIndex: number[]
    partIndex: number[]
    ticketIndex: number[]
    departureIataCode: (string | number)[]
    arrivalIataCode: (string | number)[]
    departureTime: (string | number)[]
    arrivalTime: (string | number)[]
    flightTime: (string | number)[]
    operatingCarrier: (string | number)[]
    marketingCarrier: (string | number)[]
    marketingFlightNumber: (string | number)[]
  }
  segmentsNumber: number
  totalAmount: number
}

type Indexes = {
  idx: number
  arr: number[]
}

const getIndexes = (parts: Part[]) => {
  const ticket: Indexes = { idx: 1, arr: [] }
  const part: Indexes = { idx: 1, arr: [] }
  const segment: Indexes = { idx: 0, arr: [] }

  let counter = 0

  parts.forEach(({ segments }) => {
    segments.forEach(() => {
      counter++
      segment.idx = counter
      ticket.arr.push(ticket.idx)
      part.arr.push(part.idx)
      segment.arr.push(segment.idx)
    })
  })

  return {
    segmentIndex: segment.arr,
    partIndex: part.arr,
    ticketIndex: ticket.arr,
  }
}

const getArrSegmentFields = (
  parts: Part[],
  field: keyof Pick<
    Segment,
    | 'departureTime'
    | 'arrivalTime'
    | 'durationMs'
    | 'operatingCarrier'
    | 'marketingCarrier'
    | 'flightNumber'
  >
) => {
  return parts
    .map(({ segments }) => segments.map((segment) => segment[field]))
    .flat()
}

const checkIsSmart = (parts: Part[]): boolean => {
  return parts.some(({ segments }) =>
    segments.some(({ baggageReclaim }) => baggageReclaim)
  )
}

const getFlightType = (parts: Part[]): FlightType => {
  const isSmart = checkIsSmart(parts)
  const isRoundTrip = checkIsRoundTrip(parts)

  return isSmart ? (isRoundTrip ? 'split' : 'smart') : 'regular'
}

const transformTicketDetails = (
  { parts, totalAmount }: Ticket,
  selectedUpsell: Upsell | null
): TicketDetails => ({
  flightType: getFlightType(parts),
  segments: {
    ...getIndexes(parts),
    departureIataCode: parts
      .map(({ segments }) =>
        segments.map(({ departureAirport }) => departureAirport.iataCode)
      )
      .flat(),
    arrivalIataCode: parts
      .map(({ segments }) =>
        segments.map(({ arrivalAirport }) => arrivalAirport.iataCode)
      )
      .flat(),
    departureTime: getArrSegmentFields(parts, 'departureTime'),
    arrivalTime: getArrSegmentFields(parts, 'arrivalTime'),
    flightTime: getArrSegmentFields(parts, 'durationMs'),
    operatingCarrier: getArrSegmentFields(parts, 'operatingCarrier'),
    marketingCarrier: getArrSegmentFields(parts, 'marketingCarrier'),
    marketingFlightNumber: getArrSegmentFields(parts, 'flightNumber'),
  },
  segmentsNumber: parts.reduce(
    (count, { segments }) => count + segments.length,
    0
  ),
  totalAmount:
    totalAmount + (selectedUpsell ? parseFloat(selectedUpsell.totalAmount) : 0),
})

export default transformTicketDetails
