import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { all } from 'redux-saga/effects'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { initAnalytics } from 'analytics'
import ThemeProvider from 'components/ThemeProvider'
import sagas from './sagas'
import configureStore from './store'
import { configureMoment } from './configureMoment'
import App from './components/App'

configureMoment()

initAnalytics()

const initialState = {}

const { store } = configureStore(initialState)

// @ts-expect-error ts-migrate(2339) FIXME: Property 'runSaga' does not exist on type 'Store<C... Remove this comment to see the full error message
store.runSaga(function* () {
  yield all(sagas())
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app')
)
