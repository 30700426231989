import { FunctionComponent, useState, MouseEvent } from 'react'
import Button, { Props as ButtonProps } from 'components/Button'
import analytics from 'analytics'
import useConfirmed from 'utils/hooks/useConfirmed'
import ModalAddBalance from './ModalAddBalance'

export type BalanceSource = 'finance' | 'header' | 'order'

type Props = ButtonProps & {
  source: BalanceSource
}

const ButtonAddBalance: FunctionComponent<Props> = ({
  source,
  children,
  onClick,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = (event: MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true)
    onClick && onClick(event)
    analytics.account.finance.addFunds.pageLoad.success(source)
  }

  const close = () => setIsOpen(false)

  const isConfirmed = useConfirmed()

  return (
    <>
      <Button onClick={open} disabled={!isConfirmed} {...rest}>
        {children}
      </Button>

      <ModalAddBalance isOpen={isOpen} onClose={close} />
    </>
  )
}

export type { Invoice } from './ModalAddBalance'

export default ButtonAddBalance
