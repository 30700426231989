import moment from 'moment'
import { SearchData } from 'types/searchData'
import { CABIN_CLASSES_FULL } from 'types/cabinClasses'
import { Ticket } from 'types/ticket'
import defaultDateFormat from 'consts/defaultDateFormat'
import defaultDateTimeFormat from 'consts/defaultDateTimeFormat'

export type FlightDetails = {
  departureIataCode: string
  arrivalIataCode: string
  departureDate?: string
  returnDate?: string
  passengersNumber: number
  cabinClass: CABIN_CLASSES_FULL
  isDirect?: boolean
}

export const transformFlightDetailsFromTicket = (
  ticket: Ticket,
  passengersNumber: number
): FlightDetails => {
  const firstPart = ticket.parts[0]
  const firstSegment = firstPart.segments[0]
  const lastSegmentOfFirstPart =
    firstPart.segments[firstPart.segments.length - 1]

  const result: FlightDetails = {
    departureIataCode: firstSegment.departureAirport.iataCode,
    arrivalIataCode: lastSegmentOfFirstPart.arrivalAirport.iataCode,
    departureDate: moment
      .parseZone(firstSegment.departureTime, defaultDateTimeFormat)
      .format(defaultDateFormat),
    passengersNumber,
    cabinClass: ticket.cabinClass,
  }

  if (ticket.parts.length === 1) {
    return result
  }

  const lastPart = ticket.parts[ticket.parts.length - 1]
  const lastSegment = lastPart.segments[lastPart.segments.length - 1]

  result.returnDate = moment
    .parseZone(lastSegment.departureTime, defaultDateTimeFormat)
    .format(defaultDateFormat)

  return result
}

const transformFlightDetails = ({
  cabinClass,
  departureAirport,
  arrivalAirport,
  departureDate = '',
  flightBackDate,
  passengers,
  isDirect = false,
}: SearchData): FlightDetails => {
  const result: FlightDetails = {
    departureIataCode: departureAirport,
    arrivalIataCode: arrivalAirport,
    cabinClass: CABIN_CLASSES_FULL[cabinClass],
    passengersNumber: Object.values(passengers).reduce(
      (result, current) => result + current
    ),
    isDirect,
  }

  if (departureDate) {
    result.departureDate = moment(departureDate).format(defaultDateFormat)
  }

  if (flightBackDate) {
    result.returnDate = moment(flightBackDate).format(defaultDateFormat)
  }

  return result
}

export default transformFlightDetails
