import { FunctionComponent, ReactNode } from 'react'
import UIModal from '@kupibilet/ui/components/Modal'
import { DisableBodyScroll } from './styled'

type Props = {
  size?: 'wide' | 'compact' | 'thin'
  closeOnOutsideClick?: boolean
  closeOnEsc?: boolean
  shouldRenderCloseIcon?: boolean
  isOnBottom?: boolean
  scrollFix?: boolean
  onSubmitClick?: Function
  submitText?: string
  closeButtonText?: string
  shouldRenderCloseButton?: boolean
  footer?: JSX.Element
  onClose: Function
  heading?: string
  renderHeader?: () => ReactNode
  renderContent?: () => ReactNode
  isOpen: boolean
}

const Modal: FunctionComponent<Props> = ({ children, ...rest }) => {
  const { isOpen } = rest

  return (
    <>
      <UIModal {...rest}>{children}</UIModal>

      {isOpen && <DisableBodyScroll />}
    </>
  )
}

export { Header, Content, Footer } from '@kupibilet/ui/components/Modal/styled'

export default Modal
