import { FunctionComponent, ReactElement, ReactFragment } from 'react'
import { H4 } from 'components/typography'
import Spacer from 'components/Spacer'
import { Image } from 'components/Image/styled'
import { CenterWrapper } from 'components/CenterWrapper/styled'
import { Text } from './styled'
import { ReactComponent as DefaultImage } from './assets/loadingError.svg'

type Props = {
  imageUrl?: string
  title: string
  text: ReactElement | ReactFragment | string
}

const IMAGE_HEIGHT = 150

const EmptyState: FunctionComponent<Props> = ({ imageUrl, title, text }) => {
  return (
    <CenterWrapper>
      {imageUrl ? (
        <Image src={imageUrl} height={IMAGE_HEIGHT} alt={title} />
      ) : (
        <DefaultImage height={IMAGE_HEIGHT} />
      )}

      <H4>{title}</H4>
      <Spacer mb={12} />
      <Text>{text}</Text>
    </CenterWrapper>
  )
}

export default EmptyState
