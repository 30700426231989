import { decamelize } from 'humps'
import {
  ErrorDetails,
  ErrorResponse,
  APIErrorDeprecated,
} from 'api/client/errors'
import { APIError } from 'api/client/client'

type ErrorProperties = {
  errorCode: ErrorResponse['error']['code']
  errorMessage: ErrorResponse['error']['message']
  errorDetailedCode?: ErrorDetails['code'][]
  errorDetailedTarget?: ErrorDetails['target'][]
  errorDetailedMessage?: ErrorDetails['message'][]
}

const transformError = (
  error: APIErrorDeprecated | APIError | string
): ErrorProperties => {
  if (typeof error === 'string') {
    return {
      errorCode: 'client_error',
      errorMessage: error,
    }
  }

  if (error instanceof APIError) {
    const result = {
      errorCode: error.data?.code ?? 'unknown_error',
      errorMessage: error.data?.message ?? 'unknown_message',
    }

    return error.data?.details.length
      ? {
          ...result,
          errorDetailedCode: error.data?.details.map(({ code }) => code),
          errorDetailedTarget: error.data?.details.map(({ target }) => target),
          errorDetailedMessage: error.data?.details.map(
            ({ message }) => message
          ),
        }
      : result
  }

  const result = {
    errorCode: error.code,
    errorMessage: error.message,
  }

  return error.details.length
    ? {
        ...result,
        errorDetailedCode: error.details.map(({ code }) => code),
        errorDetailedTarget: error.details.map(({ target }) =>
          decamelize(target)
        ),
        errorDetailedMessage: error.details.map(({ message }) => message),
      }
    : result
}

export default transformError
