import { APIErrorDeprecated } from 'api/client/errors'
import pick from 'lodash/pick'
import setUserIdAndProperties, {
  IdAndProperties,
} from '../../../../utils/setUserIdAndProperties'
import sendData from '../../../../utils/sendData'
import sendError from '../../../../utils/sendError'
import * as pageLoad from './pageLoad'
import { StepDataOnLoad, StepFormData } from './types'

export { pageLoad }

type Data = Omit<StepDataOnLoad, 'manager'> &
  Omit<StepFormData, 'signerFullName' | 'signerPosition'> & {
    generalDirector: StepDataOnLoad['manager']
    signatoryFullName: StepFormData['signerFullName']
    signatoryPosition: StepFormData['signerPosition']
  }

const transformData = (
  companySource: StepDataOnLoad,
  formSource: StepFormData
): Data => {
  return {
    ...pick(companySource, [
      'inn',
      'kpp',
      'companyName',
      'businessAddress',
      'postalAddress',
    ]),
    generalDirector: companySource.manager,
    ...pick(formSource, ['fullName', 'phoneNumber', 'email']),
    signatoryFullName: formSource.signerFullName,
    signatoryPosition: formSource.signerPosition,
  }
}

const transformError = ({
  details,
  ...error
}: APIErrorDeprecated): APIErrorDeprecated => {
  const newDetails = details.map(({ target, ...rest }) => {
    let newTarget = target

    switch (target) {
      case 'manager':
        newTarget = 'general_director'
        break
      case 'signer.position':
        newTarget = 'signatory_position'
        break
      case 'signer.first_name':
      case 'signer.last_name':
      case 'signer.middle_name':
        newTarget = 'signatory_full_name'
    }

    return {
      target: newTarget,
      ...rest,
    }
  })

  return {
    ...error,
    details: newDetails.filter(
      ({ target }, idx, self) =>
        idx === self.findIndex((detail) => detail.target === target)
    ),
  }
}

export const complete = (
  data1: StepDataOnLoad,
  data2: StepFormData,
  properties: IdAndProperties
) => {
  setUserIdAndProperties(properties)
  sendData('account.register.step3.complete', transformData(data1, data2))
}

export const error = (
  error: APIErrorDeprecated,
  data1: StepDataOnLoad,
  data2: StepFormData
) => {
  sendError(
    'account.register.step3.error',
    transformError(error),
    transformData(data1, data2)
  )
}
