import pick from 'lodash/pick'
import sendData from '../../../../utils/sendData'
import { StepDataOnLoad } from './types'

type Data = Omit<StepDataOnLoad, 'manager'> & {
  generalDirector: StepDataOnLoad['manager']
}

const transformData = (source: StepDataOnLoad): Data => {
  return {
    ...pick(source, ['inn', 'kpp', 'companyName', 'businessAddress']),
    generalDirector: source.manager,
  }
}

export const success = (data: StepDataOnLoad) => {
  sendData('account.register.step2.page_load.success', transformData(data))
}
