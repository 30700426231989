import * as account from './events/account'
import * as searchAvia from './events/searchAvia'
import * as serpAvia from './events/serpAvia'
import * as bookingAvia from './events/bookingAvia'
import * as supportChat from './events/supportChat'
import * as orderHistory from './events/orderHistory'
import * as employees from './events/employees'

const analytics = {
  account,
  searchAvia,
  serpAvia,
  bookingAvia,
  supportChat,
  orderHistory,
  employees,
}

export { default as initAnalytics } from './utils/initAnalytics'

export default analytics
