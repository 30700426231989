import {
  ButtonHTMLAttributes,
  cloneElement,
  FunctionComponent,
  isValidElement,
  ReactElement,
} from 'react'
import Icon, { Props as IconProps } from 'components/Icon'
import { IconWrap, StyledButton, StyledButtonText } from './styled'

export type Size = 'small' | 'normal' | 'large'

const BUTTON_SIZE_TO_ICON_MAP: Record<Size, NonNullable<IconProps['size']>> = {
  small: 'xxsmall',
  normal: 'normal',
  large: 'normal',
}

const cloneIconWithSize = (
  icon: string | ReactElement<IconProps>,
  size: Size
) => {
  const sizeByMap = BUTTON_SIZE_TO_ICON_MAP[size]

  if (!isValidElement(icon)) {
    return <Icon name={icon} size={sizeByMap} inheritColor />
  }

  return cloneElement(icon, {
    size: icon.props.size || sizeByMap,
  })
}

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: Size
  variant?: 'default' | 'primary' | 'secondary' | 'link' | 'shadow'
  shape?: 'default' | 'round'
  icon?: string | ReactElement<IconProps> | null
  leftIcon?: string | ReactElement<IconProps> | null
  rightIcon?: string | ReactElement<IconProps> | null
  disabled?: boolean
  isBlock?: boolean
  isDanger?: boolean
  isLoading?: boolean
}

const Button: FunctionComponent<Props> = ({
  size = 'normal',
  variant = 'default',
  shape = 'default',
  isLoading = false,
  icon,
  leftIcon,
  rightIcon,
  children,
  ...rest
}) => (
  <StyledButton
    size={size}
    variant={variant}
    shape={shape}
    isLoading={isLoading}
    isIconOnly={Boolean(icon)}
    hasLeftIcon={Boolean(leftIcon) || isLoading}
    hasRightIcon={Boolean(rightIcon)}
    {...rest}
  >
    {isLoading ? (
      <IconWrap size={size}>{cloneIconWithSize('spinner', size)}</IconWrap>
    ) : leftIcon ? (
      <IconWrap size={size}>{cloneIconWithSize(leftIcon, size)}</IconWrap>
    ) : null}

    {icon ? (
      !isLoading && (
        <IconWrap size={size}>{cloneIconWithSize(icon, size)}</IconWrap>
      )
    ) : (
      <StyledButtonText
        size={size}
        hasLeftIcon={Boolean(leftIcon) || isLoading}
        hasRightIcon={Boolean(rightIcon)}
      >
        {children}
      </StyledButtonText>
    )}

    {rightIcon ? (
      <IconWrap size={size}>{cloneIconWithSize(rightIcon, size)}</IconWrap>
    ) : null}
  </StyledButton>
)

export default Button
