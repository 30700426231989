import { ComponentType, lazy } from 'react'
import { Switch } from 'react-router-dom'
import { Route, AuthorizedOnlyRoute, GuestOnlyRoute } from 'components/routes'

const Confidential = lazy(() => import('pages/common/Confidential'))
const Offer = lazy(() => import('pages/common/Offer'))
const NotFound = lazy(() => import('pages/common/NotFound'))

const SignUp = lazy(() => import('pages/auth/SignUp'))
const ConfirmEmail = lazy(() => import('pages/auth/ConfirmEmail'))
const ConfirmChangeEmail = lazy(() => import('pages/auth/ConfirmChangeEmail'))
const SignIn = lazy(() => import('pages/auth/SignIn'))
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'))
const ConfirmPassword = lazy(() => import('pages/auth/ConfirmPassword'))

const Profile = lazy(() => import('pages/account/Profile'))

const Main = lazy(() => import('pages/search/Main'))
const Results = lazy(() => import('pages/search/Results'))
const Booking = lazy(() => import('pages/search/Booking'))

const Orders = lazy(() => import('pages/orders/Orders'))
const Order = lazy(() => import('pages/orders/Order'))

const Employees = lazy(() => import('pages/employees/Employees'))
const Employee = lazy(() => import('pages/employees/Employee'))

const Payments = lazy(() => import('pages/payments/Payments'))

type RouteDescription = {
  path: string
  component: ComponentType
  title: string
  confirmedOnly?: boolean
  desktopOnly?: boolean
}

const routes: RouteDescription[] = [
  {
    path: '/confidential',
    component: Confidential,
    title: 'Политика конфиденциальности',
  },
  {
    path: '/offer',
    component: Offer,
    title: 'Публичная оферта',
  },
  {
    path: '/email-confirmation',
    component: ConfirmEmail,
    title: 'Подтверждение почты',
  },
  {
    path: '/email-change-confirmation',
    component: ConfirmChangeEmail,
    title: 'Подтверждение смены почты',
  },
  {
    path: '/password-confirmation',
    component: ConfirmPassword,
    title: 'Ввод нового пароля',
  },
]

const guestRoutes: RouteDescription[] = [
  {
    path: '/sign-up',
    component: SignUp,
    title: 'Регистрация',
    desktopOnly: true,
  },
  {
    path: '/sign-in',
    component: SignIn,
    title: 'Вход',
    desktopOnly: true,
  },
  {
    path: '/forgot-password',
    component: ResetPassword,
    title: 'Восстановление пароля',
    desktopOnly: true,
  },
]

const authorizedRoutes: RouteDescription[] = [
  { path: '/', component: Main, title: 'Главная', desktopOnly: true },
  { path: '/profile', component: Profile, title: 'Аккаунт', desktopOnly: true },
  {
    path: '/search/:searchQuery',
    component: Results,
    title: 'Поиск авиабилетов',
    desktopOnly: true,
  },
  {
    path: '/search/:searchQuery/:encodedId',
    component: Booking,
    title: 'Бронирование билета',
    desktopOnly: true,
  },
  { path: '/orders', component: Orders, title: 'Поездки', desktopOnly: true },
  {
    path: '/orders/:id',
    component: Order,
    title: 'Поездка',
    confirmedOnly: true,
    desktopOnly: true,
  },
  {
    path: '/employees',
    component: Employees,
    title: 'Сотрудники',
    desktopOnly: true,
  },
  {
    path: '/employees/add',
    component: Employee,
    title: 'Новый сотрудник',
    confirmedOnly: true,
    desktopOnly: true,
  },
  {
    path: '/employees/:id',
    component: Employee,
    title: 'Профиль сотрудника',
    confirmedOnly: true,
    desktopOnly: true,
  },
  {
    path: '/payments',
    component: Payments,
    title: 'Финансовые операции',
    desktopOnly: true,
  },
]

const Router = () => {
  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <Route key={title} title={title} {...rest} exact />
      ))}

      {guestRoutes.map(({ title, ...rest }) => (
        <GuestOnlyRoute key={title} title={title} {...rest} exact />
      ))}

      {authorizedRoutes.map(({ title, ...rest }) => (
        <AuthorizedOnlyRoute key={title} title={title} {...rest} exact />
      ))}

      <Route component={NotFound} title="Страница не найдена" />
    </Switch>
  )
}

export default Router
