import { OrderStatus } from 'types/api/order'
import { Order } from 'types/order'
import { Upsell } from 'types/api/ticket'
import transformTicketDetails, {
  TicketDetails,
} from '../../../utils/transformTicketDetails'
import {
  FlightDetails,
  transformFlightDetailsFromTicket,
} from '../../../utils/transformFlightDetails'
import transformAddFareDetails, {
  AddFareDetails,
} from '../../../utils/transformAddFareDetails'
import sendData from '../../../utils/sendData'

type Data = FlightDetails &
  TicketDetails &
  AddFareDetails & {
    orderId: string
    state: OrderStatus
  }

export const success = (order: Order, selectedUpsell: Upsell | null) => {
  const { id, status, flight, passengers } = order

  const data: Data = {
    orderId: id,
    state: status,
    ...transformFlightDetailsFromTicket(flight, passengers.length),
    ...transformTicketDetails(flight, selectedUpsell),
    ...transformAddFareDetails(flight, selectedUpsell),
  }

  sendData('order_history.order.page_load.success', data)
}
