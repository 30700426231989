import { FunctionComponent } from 'react'
import {
  Redirect,
  Route as ReactRoute,
  RouteProps,
  useLocation,
} from 'react-router-dom'
import { Location as ReactRouterLocation } from 'history'
import useTitle from 'utils/hooks/useTitle'
import useConfirmed from 'utils/hooks/useConfirmed'
import Auth from 'contexts/auth'
import MobilePlug from './MobilePlug'

export type Props = Omit<RouteProps, 'path'> & {
  path?: string
  title: string
  desktopOnly?: boolean
}

export const Route: FunctionComponent<Props> = ({
  title,
  desktopOnly,
  ...rest
}) => {
  useTitle(title)

  return (
    <>
      <ReactRoute {...rest} />

      {desktopOnly && <MobilePlug />}
    </>
  )
}

type GuestLocationState = {
  targetLocation?: ReactRouterLocation
}

export const GuestOnlyRoute: FunctionComponent<Props> = (props) => {
  const { auth } = Auth.useContainer()
  const location = useLocation<GuestLocationState>()

  if (auth !== null) {
    const redirectTo = location.state?.targetLocation?.pathname ?? '/'

    return <Redirect from={props.path} to={redirectTo} exact />
  }

  return <Route {...props} />
}

type AuthorizedOnlyRouteProps = Props & {
  confirmedOnly?: boolean
}

export const AuthorizedOnlyRoute: FunctionComponent<AuthorizedOnlyRouteProps> = ({
  confirmedOnly,
  ...props
}) => {
  const { auth } = Auth.useContainer()
  const isConfirmed = useConfirmed()

  if (auth === null) {
    const state: GuestLocationState = {
      targetLocation: props.location,
    }

    return (
      <Redirect from={props.path} to={{ pathname: '/sign-in', state }} exact />
    )
  }

  if (confirmedOnly && !isConfirmed) {
    return <Redirect from={props.path} to="/" exact />
  }

  return <Route {...props} />
}
