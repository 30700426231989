import { VoidFunctionComponent } from 'react'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { Container } from './styled'

const CLOSE_TIME_MSEC = 6000

const ToastContainer: VoidFunctionComponent = () => {
  return (
    <Container
      position={toast.POSITION.BOTTOM_LEFT}
      autoClose={CLOSE_TIME_MSEC}
      closeButton={false}
      transition={Slide}
      hideProgressBar
      closeOnClick={false}
      draggable={false}
    />
  )
}

export default ToastContainer
