import styled from 'styled-components/macro'
import { ToastContainer } from 'react-toastify'
import { transparentize } from 'polished'

export const Container = styled(ToastContainer)`
  .Toastify__toast {
    ${({ theme }) => theme.v2.borderRadiusCss};
    width: 448px;
    padding: 16px;
    background: ${({ theme }) => theme.color.background};
    border: 1px solid ${({ theme }) => theme.color.miscLight};
    box-shadow: 0 2px 8px 0
      ${({ theme }) => transparentize(0.84, theme.color.textDarkest)};
  }
`
