import { useEffect, useState } from 'react'
import { PointName, points, Variant } from 'utils/media'

const useMedia = (point: PointName, variant: Variant) => {
  const property = variant === 'down' ? 'max-width' : 'min-width'
  const size = variant === 'down' ? points[point] - 1 : points[point]
  const mediaString = `(${property}: ${size}px)`

  const [isMatch, setIsMatch] = useState(matchMedia(mediaString).matches)

  useEffect(() => {
    const media = matchMedia(mediaString)

    const listener = () => {
      setIsMatch(media.matches)
    }

    // В Safari 13 нет addEventListener
    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [mediaString])

  return isMatch
}

export default useMedia
