import { decamelize } from 'humps'
import sendData from '../../../utils/sendData'
import getAddedAndChangedFields from '../../../utils/getAddedAndChangedFields'
import * as pageLoad from './pageLoad'
import * as changing from './changing'

export { pageLoad, changing }

type ValuesPersonal = {
  firstName: string
  lastName: string
  phoneNumber: string
}

type ValuesEmail = {
  email: string
}

type ValuesPassword = {
  password: string
}

type Values = ValuesPersonal | ValuesEmail | ValuesPassword

type Data = {
  fieldsChanged: (keyof Values)[]
}

export const changed = (values: Values, initialValues?: Values) => {
  const data: Data = {
    fieldsChanged: initialValues
      ? getAddedAndChangedFields(values, initialValues).fieldsChanged
      : (Object.keys(values) as (keyof Values)[]),
  }

  sendData('account.profile_settings.changed', {
    fieldsChanged: data.fieldsChanged.map((v) => decamelize(v)),
  })
}
