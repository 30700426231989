import { Passenger } from 'types/api/order'
import sendData from '../../../utils/sendData'

type ChangeType = 'adding' | 'deletion'

type Data = {
  changeType: ChangeType
  ticketId: string
  passengersChangedIds: string[]
  passengersChangedNumber: number
}

export const complete = (
  changeType: ChangeType,
  ticketId: string,
  passengers: Passenger[]
) => {
  const data: Data = {
    changeType,
    ticketId,
    passengersChangedIds: passengers.map(({ id }) => id),
    passengersChangedNumber: passengers.length,
  }

  sendData('booking_avia.passengers.changing.complete', data)
}
