import styled from 'styled-components/macro'
import { Link } from 'components/Link/styled'

export const Wrap = styled.header`
  background: ${({ theme }) => theme.color.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.miscLighter};
  padding: 16px 0;
`

export const MenuItem = styled(Link)`
  line-height: 40px;
`
