import uniq from 'lodash/uniq'
import { Ticket } from 'types/ticket'
import { Upsell } from 'types/api/ticket'
import checkIsRoundTrip from './checkIsRoundTrip'

export type AddFareDetails = {
  addFareTo: string[]
  addFareBack?: string[]
  addFareAvailableTo: string[]
  addFareAvailableBack?: string[]
}

const makeUpsellName = (upsell: Upsell | null): string => {
  if (!upsell) {
    return 'standard'
  }

  const {
    name,
    baggage: {
      baggage: { count, weight },
    },
  } = upsell

  return `${count ? `${count}-` : ''}${name}${weight ? `-${weight}` : ''}`
}

const transformAddFareDetails = (
  { parts, upsell }: Ticket,
  selectedUpsell: Upsell | null
): AddFareDetails => {
  const addFareTo = [makeUpsellName(selectedUpsell)]
  const addFareAvailableTo = uniq([
    makeUpsellName(upsell),
    makeUpsellName(null),
  ])

  const result: AddFareDetails = {
    addFareTo,
    addFareAvailableTo,
  }

  if (checkIsRoundTrip(parts)) {
    result.addFareBack = addFareTo
    result.addFareAvailableBack = addFareAvailableTo
  }

  return result
}

export default transformAddFareDetails
