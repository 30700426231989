import { FunctionComponent, Ref, InputHTMLAttributes } from 'react'
import { InputStyled } from './styled'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  error?: string | null
  success?: boolean
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  isTextarea?: boolean
  innerRef?: Ref<HTMLInputElement>
}

const Input: FunctionComponent<Props> = (props) => {
  return <InputStyled {...props} />
}

export default Input
