import { ChangeEvent, FocusEvent, FunctionComponent, ReactNode } from 'react'
import { useField } from 'formik'
import FormWrap from 'components/FormWrap'
import Input, { Props as InputType } from 'components/Input'
import { getValidationError } from 'components/forms/validation'

type Props = Omit<InputType, 'id' | 'error' | 'success'> & {
  label: string
  labelRight?: ReactNode
  description?: string
}

const FormInput: FunctionComponent<Props> = ({
  label,
  labelRight,
  description,
  onBlur,
  onChange,
  ...props
}) => {
  const { name = '' } = props

  const [input, meta] = useField(name)

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
    onBlur && onBlur(event)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange(event)
    onChange && onChange(event)
  }

  return (
    <FormWrap
      name={name}
      label={label}
      labelRight={labelRight}
      description={description}
    >
      <Input
        id={input.name}
        name={input.name}
        onBlur={handleBlur}
        onChange={handleChange}
        {...meta}
        {...props}
        error={getValidationError(meta)}
      />
    </FormWrap>
  )
}

export default FormInput
