import {
  FunctionComponent,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'
import moment from 'moment'
import defaultDateFormat from 'consts/defaultDateFormat'
import { useGetBalance } from 'api/balance'

type BalanceResponse = {
  amount: string
  currency: 'RUB'
  credit?: {
    expiryDate: string
    amount: string
  }
}

type Balance = {
  amount: number
  creditAmount: number
  creditExpiryDate?: string
  isDatePassed: boolean
}

type BalanceContextType = {
  balance: Balance
  updateBalance: () => void
}

const EMPTY: Balance = {
  amount: 0,
  creditAmount: 0,
  isDatePassed: false,
}

const BalanceContext = createContext<BalanceContextType>({
  balance: EMPTY,
  updateBalance: () => null,
})

const BalanceProvider: FunctionComponent = ({ children }) => {
  const [balance, setBalance] = useState<Balance>(EMPTY)

  const [getBalance] = useGetBalance()

  const updateBalance = useCallback(() => {
    getBalance(({ data }: { data: BalanceResponse }) => {
      const { amount, credit } = data

      const isDatePassed = credit?.expiryDate
        ? parseInt(amount, 10) < 0 &&
          !moment(credit.expiryDate, defaultDateFormat).isAfter(moment(), 'd')
        : false

      setBalance({
        amount: parseInt(amount, 10),
        creditAmount: credit ? parseInt(credit.amount, 10) : 0,
        creditExpiryDate: credit?.expiryDate,
        isDatePassed,
      })
    })
  }, [getBalance])

  const authContextValue = useMemo(
    () => ({
      balance,
      updateBalance,
    }),
    [balance, updateBalance]
  )

  return (
    <BalanceContext.Provider value={authContextValue}>
      {children}
    </BalanceContext.Provider>
  )
}

export const useBalanceContext = () => {
  return useContext(BalanceContext)
}

export default BalanceProvider
