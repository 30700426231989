import useAPIDeprecated, {
  useCallAPIWithoutParams,
} from './client/useAPIDeprecated'

export const useGetBalance = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'get',
    endpoint: '/balance',
    cancelable: false,
  })
  return [useCallAPIWithoutParams(callAPI), states] as const
}
