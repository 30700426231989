import { Link as RouterLink, useLocation } from 'react-router-dom'
import requisites from 'consts/requisites'
import Icon from 'components/Icon'
import { Link } from 'components/Link/styled'
import Popover from 'components/Popover'
import { DropdownContentStyled, IconWrapper, LinkStyled } from './styled'
import LinkSignOut from './LinkSignOut'

const { domain } = requisites

const Account = () => {
  const { pathname } = useLocation()

  return (
    <Popover
      content={
        <DropdownContentStyled>
          <LinkStyled
            as={RouterLink}
            to="/payments"
            data-test-id="headerPaymentsLink"
            disabled={pathname === '/payments'}
          >
            Финансовые операции
          </LinkStyled>

          <LinkStyled
            as={RouterLink}
            to="/profile"
            data-test-id="headerMeLink"
            disabled={pathname === '/profile'}
          >
            Настройки аккаунта
          </LinkStyled>

          <LinkStyled
            href={`https://help.${domain.text}/`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            База знаний
          </LinkStyled>

          <LinkSignOut>Выйти</LinkSignOut>
        </DropdownContentStyled>
      }
      placement="bottomRight"
    >
      <Link>
        <IconWrapper>
          <Icon name="man" size="normal" fill="primaryDarkest" />
        </IconWrapper>

        <span>Аккаунт</span>
      </Link>
    </Popover>
  )
}

export default Account
