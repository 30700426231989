import { useEffect } from 'react'

const useDebounceEffect = (
  callback: () => void,
  deps: any[],
  delayMs: number = 300
) => {
  return useEffect(() => {
    const timeout = setTimeout(callback, delayMs)
    return () => clearTimeout(timeout)
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useDebounceEffect
