import { useCallback, useState, useEffect } from 'react'
import { Auth, CompanyAuth, UserAuth } from 'types/api/auth'
import { AccountStorage } from 'lib/storage'
import { createContainer } from 'lib/state/container'

const AUTH_KEY = 'auth'

type AuthDataPatch = {
  user?: Partial<UserAuth>
  company?: Partial<CompanyAuth>
}

const useAuth = () => {
  const [auth, setAuth] = useState<Auth | null>(() => {
    const savedAuth = AccountStorage.getItem(AUTH_KEY)

    return savedAuth ? JSON.parse(savedAuth) : null
  })

  const signIn = useCallback((newAuth: Auth) => {
    setAuth(newAuth)
  }, [])

  const signOut = useCallback(() => {
    setAuth(null)
  }, [])

  const updateAuthData = useCallback((dataPatch: AuthDataPatch) => {
    setAuth((currentAuth) => {
      if (currentAuth === null) {
        throw new Error(`can't update data for unauthenticated user`)
      }

      return {
        ...currentAuth,
        user: {
          ...currentAuth.user,
          ...dataPatch.user,
        },
        company: {
          ...currentAuth.company,
          ...dataPatch.company,
        },
      }
    })
  }, [])

  // Sync to storage
  useEffect(() => {
    if (auth === null) {
      AccountStorage.clear()
      return
    }

    AccountStorage.setItem(AUTH_KEY, JSON.stringify(auth))
  }, [auth])

  // Sync from storage
  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (AccountStorage.isKeyEquals(event.key, AUTH_KEY)) {
        const newAuth = event.newValue ? JSON.parse(event.newValue) : null
        setAuth(newAuth)
      }
    }

    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  return {
    auth,
    signIn,
    signOut,
    updateAuthData,
  }
}

export default createContainer(useAuth)
