import { createGlobalStyle, css } from 'styled-components/macro'
import media from 'utils/media'
import { H3 } from 'components/typography'
import Logo from 'components/Logo'
import Spacer from 'components/Spacer'
import MobileAppLinks from 'components/MobileAppLinks'
import { Wrap, Inner } from './styled'

const GlobalStyle = createGlobalStyle`
  body {
    ${media.down.lg(css`
      overflow: hidden;
    `)}
  }
`

const MobilePlug = () => {
  return (
    <Wrap>
      <Inner>
        <Spacer mb={30}>
          <Logo />
        </Spacer>

        <Spacer mb={20}>
          <H3>Скачайте наше приложение</H3>
        </Spacer>

        <Spacer mb={30}>
          К сожалению, пользоваться сайтом можно только с компьютера. Скачайте
          наше приложение для удобства использования.
        </Spacer>

        <MobileAppLinks />
      </Inner>

      <GlobalStyle />
    </Wrap>
  )
}

export default MobilePlug
