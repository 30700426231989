import { camelizeKeys, decamelizeKeys } from 'humps'

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete'

type Options = {
  params?: any
  data?: any
  responseType?: 'json' | 'pdf'
}

type CancelablePromise<Response> = Promise<Response> & {
  cancel?: () => void
}

export type Client = (
  method: Method,
  endpoint: string,
  options?: Options
) => CancelablePromise<unknown>

type ErrorDetails = {
  code: string
  args: Record<string, unknown>
  target: string
  message: string
}

// Error response from API
type ErrorData = {
  code: string
  details: ErrorDetails[]
  message: string
}

export class APIError extends Error {
  constructor(
    public httpStatus?: number,
    public data?: ErrorData,
    message?: string
  ) {
    super(message)
    this.name = 'APIError'
  }
}

export const createClient = (
  fetch: typeof window.fetch,
  authToken: string | null,
  onUnauthenticatedError: () => void
): Client => {
  const baseUrl = (B2B_APP_CONFIG.API_URL || '').replace(/\/?$/, '')

  return (method, endpoint, options) => {
    const preparedEndpoint = endpoint.replace(/^\//, '')

    const params = options?.params
      ? // @ts-expect-error Wrong type for decamelizeKeys
        new URLSearchParams(decamelizeKeys(options.params))
      : null
    const paramsStr = params ? `?${params.toString()}` : ''

    const fullUrl = `${baseUrl}/${preparedEndpoint}${paramsStr}`

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      Accept:
        options?.responseType === 'pdf'
          ? 'application/pdf'
          : 'application/json',
    }

    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`
    }

    const abortController = new AbortController()

    const request: CancelablePromise<unknown> = fetch(fullUrl, {
      method: method.toUpperCase(),
      headers,
      signal: abortController.signal,
      body: options?.data
        ? JSON.stringify(decamelizeKeys(options.data))
        : undefined,
    })
      .then((response) => {
        if (response.ok) {
          if (options?.responseType === 'pdf') {
            return response.blob()
          }

          if (method === 'delete') {
            return response
          }

          return response.json().then(camelizeKeys)
        }

        if (response.status >= 400 && response.status < 500) {
          return response.json().then((data) => {
            throw new APIError(
              response.status,
              data?.error,
              `${response.status} ${data?.error?.message}`
            )
          })
        }

        throw new APIError(
          response.status,
          undefined,
          `${response.status} server error`
        )
      })
      .catch((error) => {
        if (error instanceof APIError) {
          if (error.httpStatus === 401) {
            onUnauthenticatedError()
          }

          // Is known error from API, rethrow it
          throw error
        }

        // Fetch error or json parse error
        throw new APIError(undefined, undefined, error.message)
      })

    request.cancel = () => {
      abortController.abort()
    }

    return request
  }
}
