import { OrderDocument, OrderDocumentType } from 'types/api/orderDocument'
import sendData from '../../../utils/sendData'

type Data = {
  destination: 'email' | 'device'
  passengerIds: OrderDocument['employeeId'][]
  documents: OrderDocumentType[]
}

export const documentShare = (
  document: OrderDocument,
  destination: 'email' | 'device'
) => {
  const data: Data = {
    destination,
    passengerIds: [document.employeeId],
    documents: [document.type],
  }

  sendData('order_history.order.passenger_docs.document_share', data)
}
