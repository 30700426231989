import { VoidFunctionComponent } from 'react'
import NumberFormat from 'react-number-format'

type Props = {
  value: number
}

const Currency: VoidFunctionComponent<Props> = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      suffix="&nbsp;₽"
      thousandSeparator="&nbsp;"
      decimalScale={0}
    />
  )
}
export default Currency
