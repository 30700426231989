import useAPIDeprecated, {
  useCallAPIWithoutParams,
  useCallAPIWithPayload,
} from './client/useAPIDeprecated'

export const useAddInvoice = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/invoices',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export const useGetPdf = (id: string) => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'get',
    endpoint: `/invoices/${id}`,
    isPdf: true,
  })
  return [useCallAPIWithoutParams(callAPI), states] as const
}
