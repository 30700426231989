const requisites = {
  domain: {
    text: 'b2b.kupibilet.ru',
    url: 'https://b2b.kupibilet.ru/',
  },
  name: 'ЗАО «НОРДВЕСТ»',
  nameFull: 'Закрытое акционерное общество «Нордвест»',
  email: 'northwest-co@list.ru',
  emailSupport: 'support.b2b@kupibilet.ru',
  telMain: {
    text: '+7 (812) 334-07-34',
    url: '+78123340734',
  },
  telSecondary: {
    text: '570-23-85',
    url: '+78125702385',
  },
  businessAddress:
    '190031, г. Санкт-Петербург, Спасский пер. д.12, литер А, пом. 2Н',
  actualAddress:
    '190031, г. Санкт-Петербург, Спасский пер. д.12, литер А, пом. 2Н',
  inn: '7816223903',
  kpp: '783801001',
  okpo: '13886330',
  ogrn: '1037835055125',
  checkingAccount:
    '40702810635000002127 в ПАО «Банк Санкт-Петербург» в г. Санкт-Петербург',
  correspondingAccount: '30101810900000000790',
  bik: '044030790',
  manager: 'Будряшкина М.В.',
  appUrls: {
    ios: 'https://apps.apple.com/ru/app/id1551702324',
    android:
      'https://play.google.com/store/apps/details?id=ru.kupibiletb2b.beta',
  },
} as const

export default requisites
