import {
  FunctionComponent,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

type ContextType = {
  amount: number | null
  setAmount: (price: number | null) => void
}

const Context = createContext<ContextType>({
  amount: null,
  setAmount: () => null,
})

const TicketAmountProvider: FunctionComponent = ({ children }) => {
  const [amount, setAmount] = useState<number | null>(null)

  const contextValue = useMemo(
    () => ({
      amount,
      setAmount,
    }),
    [amount]
  )

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const useTicketAmountContext = () => {
  return useContext(Context)
}

export default TicketAmountProvider
