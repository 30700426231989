import { Passenger } from 'types/api/order'
import { Upsell } from 'types/api/ticket'
import { Ticket } from 'types/ticket'
import { APIErrorDeprecated } from 'api/client/errors'
import transformTicketDetails, {
  TicketDetails,
} from '../../utils/transformTicketDetails'
import {
  FlightDetails,
  transformFlightDetailsFromTicket,
} from '../../utils/transformFlightDetails'
import transformAddFareDetails, {
  AddFareDetails,
} from '../../utils/transformAddFareDetails'
import sendData from '../../utils/sendData'
import sendError from '../../utils/sendError'
import * as pageLoad from './pageLoad'
import * as passengers from './passengers'

export { pageLoad, passengers }

type Choice = 'add_to_order' | 'buy_ticket' // Черновик | Оплата

type Data = FlightDetails &
  TicketDetails &
  AddFareDetails & {
    choice: Choice
    ticketId: string
  }

type ErrorData = Omit<Data, 'choice'>

export const complete = (
  choice: Choice,
  ticket: Ticket,
  passengers: Passenger[],
  selectedUpsell: Upsell | null
) => {
  const data: Data = {
    choice,
    ticketId: ticket.id,
    ...transformFlightDetailsFromTicket(ticket, passengers.length),
    ...transformTicketDetails(ticket, selectedUpsell),
    ...transformAddFareDetails(ticket, selectedUpsell),
  }

  sendData('booking_avia.complete', data)
}

export const error = (
  error: APIErrorDeprecated,
  ticket: Ticket,
  passengers: Passenger[],
  selectedUpsell: Upsell | null
) => {
  const data: ErrorData = {
    ticketId: ticket.id,
    ...transformFlightDetailsFromTicket(ticket, passengers.length),
    ...transformTicketDetails(ticket, selectedUpsell),
    ...transformAddFareDetails(ticket, selectedUpsell),
  }

  sendError('booking_avia.error', error, data)
}
