import { Ticket } from 'types/ticket'
import transformTicketDetails, {
  TicketDetails,
} from '../../utils/transformTicketDetails'
import {
  FlightDetails,
  transformFlightDetailsFromTicket,
} from '../../utils/transformFlightDetails'
import transformAddFareDetails, {
  AddFareDetails,
} from '../../utils/transformAddFareDetails'
import sendData from '../../utils/sendData'

type Data = FlightDetails &
  TicketDetails &
  AddFareDetails & {
    ticketId: string
  }

export const success = (ticket: Ticket) => {
  const passengersNumber = ticket.amountDetails.reduce((acc, { count }) => {
    return acc + count
  }, 0)

  const data: Data = {
    ticketId: ticket.id,
    ...transformFlightDetailsFromTicket(ticket, passengersNumber),
    ...transformTicketDetails(ticket, null),
    ...transformAddFareDetails(ticket, null),
  }

  sendData('booking_avia.page_load.success', data)
}
