import { VoidFunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useBalanceContext } from 'contexts/BalanceProvider'
import Currency from 'components/Currency'
import { Link } from 'components/Link/styled'

const Amount: VoidFunctionComponent = () => {
  const {
    balance: { amount, creditAmount, isDatePassed },
  } = useBalanceContext()

  return (
    <Link as={RouterLink} to="/payments" data-test-id="headerPaymentsLink">
      <Currency value={isDatePassed ? 0 : amount + creditAmount} />
    </Link>
  )
}

export default Amount
