import { APIErrorDeprecated } from 'api/client/errors'
import { Invoice } from 'components/ButtonAddBalance'
import sendData from '../../../../utils/sendData'
import sendError from '../../../../utils/sendError'

export const complete = (data: Invoice) => {
  sendData('account.finance.add_funds.invoice_share.complete', {
    ...data,
    invoiceType: 'pdf',
  })
}

export const error = (error: APIErrorDeprecated, data: Invoice) => {
  sendError('account.finance.add_funds.invoice_share.error', error, {
    ...data,
    invoiceType: 'pdf',
  })
}
