import { Passenger } from 'types/api/order'
import { Upsell } from 'types/api/ticket'
import { Ticket } from 'types/ticket'
import { APIErrorDeprecated } from 'api/client/errors'
import transformTicketDetails, {
  TicketDetails,
} from '../../../utils/transformTicketDetails'
import {
  FlightDetails,
  transformFlightDetailsFromTicket,
} from '../../../utils/transformFlightDetails'
import transformAddFareDetails, {
  AddFareDetails,
} from '../../../utils/transformAddFareDetails'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'

type Data = FlightDetails &
  TicketDetails &
  AddFareDetails & {
    orderId: string
    passengersIds: string[]
    passengersActualNumber: number
  }

const transformData = (
  orderId: string,
  ticket: Ticket,
  selectedUpsell: Upsell | null,
  passengers: Passenger[]
): Data => {
  return {
    orderId,
    ...transformFlightDetailsFromTicket(ticket, passengers.length),
    ...transformTicketDetails(ticket, selectedUpsell),
    ...transformAddFareDetails(ticket, selectedUpsell),
    passengersIds: passengers.map(({ id }) => id),
    passengersActualNumber: passengers.length,
  }
}

export const start = (
  orderId: string,
  ticket: Ticket,
  selectedUpsell: Upsell | null,
  passengers: Passenger[]
) => {
  sendData(
    'order_history.order.issuing.start',
    transformData(orderId, ticket, selectedUpsell, passengers)
  )
}

export const complete = (
  orderId: string,
  ticket: Ticket,
  selectedUpsell: Upsell | null,
  passengers: Passenger[]
) => {
  sendData(
    'order_history.order.issuing.complete',
    transformData(orderId, ticket, selectedUpsell, passengers)
  )
}

export const error = (
  error: APIErrorDeprecated,
  orderId: string,
  ticket: Ticket,
  selectedUpsell: Upsell | null,
  passengers: Passenger[]
) => {
  sendError(
    'order_history.order.issuing.error',
    error,
    transformData(orderId, ticket, selectedUpsell, passengers)
  )
}
