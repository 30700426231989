import styled, { css } from 'styled-components/macro'

type Size = 14 | 16 | 18 | 20 | 24 | 32

type Color = 'text' | 'textDarkest' | 'miscDark' | 'fail' | 'success'

type Props = {
  size?: Size
  lineHeight?: number
  isBold?: boolean
  color?: Color
}

export const Text = styled.div<Props>`
  font-size: ${({ size = 16 }) => `${size}px`};
  color: ${({ theme, color = 'textDarkest' }) => theme.color[color]};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `};
`
