import { css, DefaultTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

const durationSec = 0.3

const borderRadius = {
  sm: 3,
  lg: 6,
}

const borderRadiusV2 = 16

const colorLightBlue = '#c6e4f2'
const colorLightError = '#f9b9b9'

export type ThemeColor = keyof DefaultTheme['color']

const theme: DefaultTheme = {
  color: {
    background: '#ffffff',
    fail: '#e64926',
    failDark: '#cf4122',
    failDarker: '#b83a1e',
    failLight: '#e85b3b',
    failLighter: '#eb6d51',
    failLightest: '#fbe2dd',

    success: '#23c256',
    successLightest: '#dcf5e4',

    primary: '#38afff',
    primaryDark: '#0f99f5',
    primaryDarker: '#008ae6',
    primaryDarkest: '#007acc',
    primaryLight: '#77c8ff',
    primaryLighter: '#a9ddff',
    primaryLightest: '#e5f5ff',

    secondary: '#ff9100',
    secondaryDark: '#ff7d03',
    secondaryDarker: '#fc6100',
    secondaryDarkest: '#fa3a00',
    secondaryLight: '#ffb640',
    secondaryLighter: '#ffd787',
    secondaryLightest: '#fff0bf',
    secondarySoft: '#fffbf0',

    text: '#727272',
    textDark: '#4d4d4d',
    textDarker: '#333333',
    textDarkest: '#222222',
    textLight: '#b3b3b3',
    textLighter: '#dedede',
    textLightest: '#f6f6f8',

    misc: '#b1bdcc',
    miscDark: '#95a0b3',
    miscDarker: '#7d89a1',
    miscDarkest: '#62708b',
    miscLight: '#cad3de',
    miscLighter: '#dde3eb',
    miscLightest: '#f0f5fa',
  },
  font: css`
    font-family: 'Source Sans Pro', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.4;
  `,
  durationSec,
  transition: css`
    transition: ${durationSec}s ease;
  `,
  borderRadius,
  borderRadiusSm: css`
    border-radius: ${borderRadius.sm}px;
  `,
  borderRadiusLg: css`
    border-radius: ${borderRadius.lg}px;
  `,
  shadow: css`
    box-shadow: 0 3px 9px 0 rgba(98, 112, 139, 0.7);
  `,
  v2: {
    colors: {
      text: {
        default: '#39464c',
        secondary: '#858a8d',
        disabled: '#b0b5b7',
        danger: '#ef4f4f',
      },
      blue: {
        default: '#0d8dc8',
        light: colorLightBlue,
      },
      danger: {
        light: colorLightError,
      },
      gray: {
        default: '#dfe1e2',
      },
    },
    shadows: {
      focus: `0 0 8px 0 ${transparentize(0.2, colorLightBlue)}`,
      dangerFocus: `0 0 8px 0 ${transparentize(0.2, colorLightError)}`,
    },
    borderRadius: borderRadiusV2,
    borderRadiusCss: css`
      border-radius: ${borderRadiusV2}px;
    `,
    button: {
      colors: {
        color: '#fff',
        bg: {
          hover: '#47a9d6',
          active: '#177caa',
        },
        bgSecondary: {
          hover: '#e7e9e9',
          active: '#b7bcbe',
          disabled: '#edeeef',
        },
      },
    },
    input: {
      colors: {
        border: {
          disabled: '#e9eaeb',
        },
        bg: {
          default: '#fff',
          disabled: '#fcfcfc',
        },
      },
    },
  },
}

export default theme
