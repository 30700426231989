import { SearchData } from 'types/searchData'
import sendData from '../../utils/sendData'
import transformFlightDetails, {
  FlightDetails,
} from '../../utils/transformFlightDetails'
import * as pageLoad from './pageLoad'
import * as searchForm from './searchForm'
import * as ticket from './ticket'
import * as filterAndSort from './filterAndSort'

export { pageLoad, searchForm, ticket, filterAndSort }

type Data = FlightDetails & {
  destination: 'link'
}

export const ticketShare = (searchData: SearchData) => {
  const data: Data = {
    ...transformFlightDetails(searchData),
    destination: 'link',
  }

  sendData('serp_avia.ticket_share', data)
}
