import sendData from '../utils/sendData'

type Data = {
  source: string
}

export const open = (data: Data) => {
  sendData('support_chat.open', data)
}

export const close = () => {
  sendData('support_chat.close')
}
