import { APIErrorDeprecated } from 'api/client/errors'
import { APIError } from 'api/client/client'
import { SaveEmployeePayload } from 'api/employees'
import { EmployeeSource } from 'types/employeeSource'
import { Employee } from 'api/employees'
import { EmployeeDocumentType } from 'api/employeeDocuments'
import { removeEmptyPropsFromObject } from 'utils/removeEmptyPropsFromObject'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'
import getAddedAndChangedFields from '../../../utils/getAddedAndChangedFields'

const docsMap = {
  Passport: 'russian_passport',
  AlienPassport: 'other',
  BirthCertificate: 'birth_certificate',
  InternationalPassport: 'international_passport',
} as const

const fieldsMap = {
  lastNameRu: 'last_name',
  firstNameRu: 'first_name',
  middleNameRu: 'middle_name',
  lastNameEn: 'last_name_latin',
  firstNameEn: 'first_name_latin',
  birthdate: 'birthday',
  gender: 'sex',
  countryCode: 'citizenship',
  email: 'email',
  phoneNumber: 'phone_number',
} as const

type valueof<T> = T[keyof T]

type Data = {
  source: EmployeeSource
  employeeId: Employee['id']
  fieldsAdded: (valueof<typeof fieldsMap> | valueof<typeof docsMap>)[]
  fieldsChanged: (valueof<typeof fieldsMap> | valueof<typeof docsMap>)[]
}

type EmployeeValues = {
  source: EmployeeSource
  employeeId: Employee['id']
  employeeValues: SaveEmployeePayload
  employeeInitialValues: SaveEmployeePayload
  document: undefined
}

type DocValues = {
  source: EmployeeSource
  employeeId: Employee['id']
  values: undefined
  initialValues: undefined
  document: {
    type: EmployeeDocumentType
    isChanged: boolean
  }
}

export const complete = (values: EmployeeValues | DocValues) => {
  const { source, employeeId } = values

  if (values.document) {
    const document = values.document

    const data: Data = {
      source,
      employeeId,
      fieldsAdded: document.isChanged ? [] : [docsMap[document.type]],
      fieldsChanged: document.isChanged ? [docsMap[document.type]] : [],
    }

    sendData('employees.card.changing.complete', data)

    return
  }

  const { employeeValues, employeeInitialValues } = values
  const { fieldsAdded, fieldsChanged } = getAddedAndChangedFields(
    removeEmptyPropsFromObject(employeeValues),
    removeEmptyPropsFromObject(employeeInitialValues)
  )

  const data: Data = {
    source,
    employeeId,
    fieldsAdded: fieldsAdded
      .filter((value) => value !== 'skipMiddleName')
      .map(
        (value) =>
          fieldsMap[value as keyof Omit<SaveEmployeePayload, 'skipMiddleName'>]
      ),
    fieldsChanged: fieldsChanged
      .filter((value) => value !== 'skipMiddleName')
      .map(
        (value) =>
          fieldsMap[value as keyof Omit<SaveEmployeePayload, 'skipMiddleName'>]
      ),
  }

  sendData('employees.card.changing.complete', data)
}

export const error = (
  error: APIErrorDeprecated | APIError,
  source: EmployeeSource,
  employeeId: string
) => {
  sendError('employees.card.changing.error', error, { source, employeeId })
}
