import { Link as RouterLink, useLocation } from 'react-router-dom'
import requisites from 'consts/requisites'
import useMedia from 'utils/hooks/useMedia'
import Auth from 'contexts/auth'
import Logo from 'components/Logo'
import Button from 'components/Button'
import Container from 'components/Container'
import Grid from 'components/Grid'
import Spacer from 'components/Spacer'
import { MENU_ITEMS } from './consts'
import { Wrap, MenuItem } from './styled'
import Balance from './Balance'
import Account from './Account'

const { Col } = Grid

const { emailSupport, domain } = requisites

const Header = () => {
  const { auth } = Auth.useContainer()
  const isAuth = Boolean(auth)

  const { pathname } = useLocation()

  const isSizeXs = useMedia('sm', 'down')
  const isSizeSm = useMedia('md', 'down')

  const renderLeftSide = () => (
    <Grid
      justify={isSizeXs ? 'center' : 'flex-start'}
      gutter={4}
      noWrap={!isSizeXs}
    >
      {!isAuth ? (
        <>
          <Col xs="auto">
            <MenuItem
              href={`https://help.${domain.text}/`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              База знаний
            </MenuItem>
          </Col>

          <Col xs="auto">
            <MenuItem
              href={`mailto:${emailSupport}`}
              data-test-id="headerSupportLink"
            >
              Написать нам
            </MenuItem>
          </Col>
        </>
      ) : (
        MENU_ITEMS.map(({ to, dataAttr, text }) => (
          <Col key={to} xs="auto">
            <MenuItem
              as={RouterLink}
              to={to}
              data-test-id={dataAttr}
              disabled={pathname === to}
            >
              {text}
            </MenuItem>
          </Col>
        ))
      )}
    </Grid>
  )

  const renderRightSide = () => {
    if (!isAuth) {
      return (
        <Grid
          gutter={[2, 1]}
          justify={isSizeXs ? 'center' : 'flex-start'}
          noWrap={!isSizeXs}
        >
          <Col xs="auto">
            <RouterLink to="/sign-up" data-test-id="headerSignUpLink">
              <Button variant="primary">Регистрация</Button>
            </RouterLink>
          </Col>

          <Col xs="auto">
            <RouterLink to="/sign-in" data-test-id="headerSignInLink">
              <Button leftIcon="signin">Вход</Button>
            </RouterLink>
          </Col>
        </Grid>
      )
    }

    return (
      <Grid
        justify={isSizeXs ? 'center' : 'flex-start'}
        gutter={[5, 1]}
        noWrap={!isSizeXs}
      >
        <Col xs="auto">
          <Balance />
        </Col>

        <Col xs="auto">
          <Account />
        </Col>
      </Grid>
    )
  }

  return (
    <Wrap>
      <Container>
        <Grid
          direction={isSizeXs ? 'column' : 'row'}
          align="center"
          gutter={[4, 3]}
        >
          <Col xs="auto">
            <Logo height={38} />
          </Col>

          {!isSizeSm && (
            <Col xs="auto">
              <Spacer mr={16} />
            </Col>
          )}

          <Col>
            <Grid align="center" justify="space-between" gutter={[4, 3]}>
              <Col sm="auto">{renderLeftSide()}</Col>
              <Col sm="auto">{renderRightSide()}</Col>
            </Grid>
          </Col>
        </Grid>
      </Container>
    </Wrap>
  )
}

export default Header
