const downloadPdf = (data: any, filename: string): void => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/pdf' })
  )

  const link = document.createElement('a')
  link.href = url
  link.download = `${filename}.pdf`
  link.click()

  window.URL.revokeObjectURL(url)
}

export default downloadPdf
