import { useEffect } from 'react'
import analytics from 'analytics'
import Auth from 'contexts/auth'
import { intercomInitializer } from './utils'

const INTERCOM_ID = 'g9vzfhhv'

const Chat = () => {
  const { auth } = Auth.useContainer()

  useEffect(() => {
    intercomInitializer(INTERCOM_ID)
  }, [])

  useEffect(() => {
    const config = auth
      ? {
          app_id: INTERCOM_ID,
          user_id: auth.user.id,
          company_id: auth.company.id,
          name: `${auth.user.firstName} ${auth.user.lastName}`,
          email: auth.user.email,
          CUSTOM_BOOLEAN_ATTRIBUTE_B2B_USER: true,
        }
      : {
          app_id: INTERCOM_ID,
        }

    Intercom('boot', config)

    Intercom('onShow', () => {
      analytics.supportChat.open({
        source: window.location.pathname,
      })
    })

    Intercom('onHide', () => {
      analytics.supportChat.close()
    })

    return () => Intercom('shutdown')
  }, [auth])

  return null
}

export default Chat
