import { useEffect, useRef } from 'react'
import { FormikContextType, useFormikContext } from 'formik'

type OnChange<Values> = (
  currState: FormikContextType<Values>,
  prevState: FormikContextType<Values> | null
) => void

type Props<Values> = {
  onChange: OnChange<Values>
}

const FormikEffect = <Values,>({ onChange }: Props<Values>) => {
  const formik = useFormikContext<Values>()

  const formikRef = useRef<FormikContextType<Values> | null>(null)
  const onChangeRef = useRef<OnChange<Values> | null>(null)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    if (!onChangeRef.current) return

    onChangeRef.current(formik, formikRef.current)

    formikRef.current = formik
  }, [formik])

  return null
}

export { handleGetErrors } from './utils'

export default FormikEffect
