import amplitude from 'amplitude-js'
import { decamelizeKeys } from 'humps'
import { CompanyAuth, UserAuth } from '../../types/api/auth'

type Properties = {
  companyId?: CompanyAuth['id']
}

export type IdAndProperties = {
  userId: UserAuth['id'] | null
} & Properties

const setUserIdAndProperties = ({ userId, ...properties }: IdAndProperties) => {
  amplitude.getInstance().setUserId(userId)
  amplitude.getInstance().setUserProperties(decamelizeKeys(properties))
}

export default setUserIdAndProperties
