import { Link as RouterLink, useLocation } from 'react-router-dom'
import Container from 'components/Container'
import { Link } from 'components/Link/styled'
import Spacer from 'components/Spacer'
import { Wrap } from './styled'

const Footer = () => {
  const { pathname } = useLocation()

  return (
    <Wrap>
      <Container>
        <Link
          as={RouterLink}
          to="/confidential"
          disabled={pathname === '/confidential'}
          target="_blank"
        >
          Политика конфиденциальности
        </Link>

        <Spacer mr={32} display="inline-block" />

        <Link
          as={RouterLink}
          to="/offer"
          disabled={pathname === '/offer'}
          target="_blank"
        >
          Публичная оферта
        </Link>
      </Container>
    </Wrap>
  )
}

export default Footer
