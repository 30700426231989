import styled from 'styled-components/macro'
import mq from '@kupibilet/ui/utils/media-queries'

export const RootWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.miscLightest};

  ${mq.handheld`
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `}
`

export const ContentWrap = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
