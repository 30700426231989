import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: flex-start;
  ${({ theme }) => theme.transition};
  fill: ${({ theme }) => theme.color.textDarker};
  transform: translateZ(0);

  &:hover {
    opacity: 0.8;
  }
`
