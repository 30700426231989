import { SearchData } from 'types/searchData'
import sendData from '../../../utils/sendData'
import transformFlightDetails, {
  FlightDetails,
} from '../../../utils/transformFlightDetails'

type Data = FlightDetails & {
  totalAmount: number
  segmentsNumber: number
}

export const open = (
  searchData: SearchData,
  totalAmount: number,
  segmentsNumber: number
) => {
  const data: Data = {
    ...transformFlightDetails(searchData),
    totalAmount,
    segmentsNumber,
  }

  sendData('serp_avia.ticket.details.open', data)
}
