import { createStore, applyMiddleware, compose } from 'redux'

import createSagaMiddleware, { END } from 'redux-saga'
import createReducer from './reducers'

// @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

const configureStore = (initialState = {}) => {
  const middlewares = [sagaMiddleware]
  const enhancers = [applyMiddleware(...middlewares)]

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers)
  )

  // Extensions
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'runSaga' does not exist on type 'Store<C... Remove this comment to see the full error message
  store.runSaga = sagaMiddleware.run
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'close' does not exist on type 'Store<Com... Remove this comment to see the full error message
  store.close = () => store.dispatch(END)

  return { store }
}

export default configureStore
