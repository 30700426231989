import { useEffect } from 'react'

const siteName = process.env.REACT_APP_SITE_NAME || ''

const useTitle = (title: string): void => {
  useEffect(() => {
    if (!title) {
      return
    }

    document.title = `${title} • ${siteName}`
  }, [title])
}

export default useTitle
