import { FunctionComponent } from 'react'
import Wrapper, { Props } from './styled/Wrapper'
import Col from './styled/Col'

type Component = FunctionComponent<Partial<Props>> & {
  Col: typeof Col
}

const Grid: Component = ({
  children,
  direction = 'row',
  align = 'normal',
  justify = 'flex-start',
  noWrap = false,
  gutter = 0,
  divider = false,
}) => {
  return (
    <Wrapper
      direction={direction}
      align={align}
      justify={justify}
      noWrap={noWrap}
      gutter={gutter}
      divider={divider}
    >
      {children}
    </Wrapper>
  )
}

Grid.Col = Col

export default Grid
