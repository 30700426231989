import { Part } from 'types/ticket'

const checkIsRoundTrip = (parts: Part[]): boolean => {
  const firstPart = parts[0]
  const lastPart = parts[parts.length - 1]

  const departureAirport = firstPart.segments[0].departureAirport
  const arrivalAirport =
    lastPart.segments[lastPart.segments.length - 1].arrivalAirport

  return departureAirport.cityName === arrivalAirport.cityName
}

export default checkIsRoundTrip
