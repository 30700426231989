import { FunctionComponent } from 'react'
import { StyledSpacer } from './styled'

export type Props = {
  p?: number
  px?: number
  py?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
  m?: number
  mx?: number
  my?: number
  mt?: number
  mb?: number
  ml?: number
  mr?: number
  display?: 'block' | 'inline-block' | 'flex' | 'inline-flex'
  alignItems?: 'center'
  textAlign?: 'left' | 'center' | 'right'
}

const Spacer: FunctionComponent<Props> = ({
  children,
  display = 'block',
  ...rest
}) => {
  return (
    <StyledSpacer display={display} {...rest}>
      {children}
    </StyledSpacer>
  )
}

export default Spacer
