import { Component } from 'react'
import EmptyState from 'components/EmptyState'
import { Link } from 'components/Link/styled'

type State = {
  hasError: boolean
}

class SuspenseErrorCatcher extends Component<{}, State> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const {
      props: { children },
      state: { hasError },
    } = this

    return hasError ? (
      <EmptyState
        title="Не удалось загрузить данные"
        text={
          <>
            Попробуйте{' '}
            <Link onClick={() => window.location.reload()}>
              перезагрузить страницу
            </Link>
          </>
        }
      />
    ) : (
      children
    )
  }
}

export default SuspenseErrorCatcher
