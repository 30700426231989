import { APIErrorDeprecated } from 'api/client/errors'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'

type RangeNames = 'currentQuarter' | 'previousQuarter' | 'calendar'

type DateRange = {
  name: RangeNames
  params: {
    createdAtFrom: string
    createdAtTo: string
  }
}

const nameToTab: Record<RangeNames, string> = {
  currentQuarter: 'current_quarter',
  previousQuarter: 'previous_quarter',
  calendar: 'calendar',
}

const transformData = (data: DateRange | null) => {
  if (!data) return {}

  const { name, params } = data

  return {
    tab: nameToTab[name],
    startDate: params.createdAtFrom,
    endDate: params.createdAtTo,
  }
}

export const complete = (data: DateRange | null) => {
  sendData('account.finance.filter.complete', transformData(data))
}

export const error = (error: APIErrorDeprecated, data: DateRange | null) => {
  sendError('account.finance.filter.error', error, transformData(data))
}
