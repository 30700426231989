import {
  css,
  DefaultTheme,
  FlattenInterpolation,
} from 'styled-components/macro'

export type PointName = 'sm' | 'md' | 'lg' | 'xl'
export type Variant = 'down' | 'up'

export const points: Record<PointName, number> = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const mediaBase = (variant: Variant) => {
  const sizeKeys = Object.keys(points) as PointName[]

  return sizeKeys.reduce((acc, key) => {
    acc[key] = (style: FlattenInterpolation<DefaultTheme>) => {
      return variant === 'down'
        ? css`
            @media (max-width: ${points[key] - 1}px) {
              ${style};
            }
          `
        : css`
            @media (min-width: ${points[key]}px) {
              ${style};
            }
          `
    }

    return acc
  }, {} as Record<PointName, Function>)
}

const media = {
  up: mediaBase('up'),
  down: mediaBase('down'),
}

export default media
