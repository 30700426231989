import requisites from 'consts/requisites'
import Grid from 'components/Grid'
import { Link } from 'components/Link/styled'
import { Image } from 'components/Image/styled'
import Spacer from 'components/Spacer'
import imageMobileApp from './assets/mobile-app.png'
import imageAppStore from './assets/app-store.svg'
import imageGooglePlay from './assets/google-play.svg'

const { Col } = Grid

const { appUrls } = requisites

const MobileAppLinks = () => {
  return (
    <>
      <Grid justify="center" gutter={[2, 2]}>
        <Col xs="auto">
          <Link
            href={appUrls.ios}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Image src={imageAppStore} alt="App Store" height={50} />
          </Link>
        </Col>

        <Col xs="auto">
          <Link
            href={appUrls.android}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Image src={imageGooglePlay} alt="Google Play" height={50} />
          </Link>
        </Col>
      </Grid>

      <Spacer mb={24} />

      <Grid justify="center" gutter={2}>
        <Col xs="auto">
          <Image src={imageMobileApp} alt="Mobile App" />
        </Col>
      </Grid>
    </>
  )
}

export default MobileAppLinks
