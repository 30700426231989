import { Suspense, useEffect } from 'react'
import Auth from 'contexts/auth'
import BalanceProvider from 'contexts/BalanceProvider'
import TicketAmountProvider from 'contexts/TicketAmountProvider'
import Router from 'routes'
import useMedia from 'utils/hooks/useMedia'
import Loading from 'components/Loading'
import ToastContainer from 'lib/toasts/ToastContainer'
import { ContentWrap, RootWrapper } from './styled'
import IconsSprite from './IconsSprite'
import Header from './Header'
import Footer from './Footer'
import SuspenseErrorCatcher from './SuspenseErrorCatcher'
import Chat from './Chat'

const App = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    body && body.classList.add('responsive')
  }, [])

  const isMobile = useMedia('lg', 'down')

  return (
    <RootWrapper id="app-wrap">
      <Auth.Provider>
        <BalanceProvider>
          <TicketAmountProvider>
            {!isMobile && <Header />}

            <ContentWrap>
              <SuspenseErrorCatcher>
                <Suspense fallback={<Loading />}>
                  <Router />
                </Suspense>
              </SuspenseErrorCatcher>
            </ContentWrap>

            <Chat />
          </TicketAmountProvider>
        </BalanceProvider>
      </Auth.Provider>

      <div id="portal" />

      <ToastContainer />

      <IconsSprite />

      {!isMobile && <Footer />}
    </RootWrapper>
  )
}

export default App
