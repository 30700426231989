import { FunctionComponent, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import analytics from 'analytics'
import { useAddInvoice, useGetPdf } from 'api/invoices'
import { APIErrorDeprecated } from 'api/client/errors'
import { useBalanceContext } from 'contexts/BalanceProvider'
import downloadPdf from 'utils/downloadPdf'
import Button from 'components/Button'
import { H3 } from 'components/typography'
import Modal, { Header } from 'components/Modal'
import FormInput from 'components/FormInput'
import Spacer from 'components/Spacer'
import Grid from 'components/Grid'
import FormWrap from 'components/FormWrap'
import Currency from 'components/Currency'
import FormError from 'components/FormError'
import FormikEffect, { handleGetErrors } from 'components/FormikEffect'
import Alert from 'components/Alert'
import { FormikFormStyled } from './styled'
import { makeIntegerFromString } from './utils'
import validation from './validation'

type Props = {
  onClose: Function
  isOpen: boolean
}

type FormValues = {
  amount: string
}

export type Invoice = {
  invoiceId?: string
  amount: string
}

const { Col } = Grid

const INITIAL_VALUES: FormValues = {
  amount: '',
}

const emptyInvoice: Invoice = {
  amount: '',
}

const ModalAddBalance: FunctionComponent<Props> = ({ onClose, isOpen }) => {
  const [invoice, setInvoice] = useState<Invoice>(emptyInvoice)

  const [addInvoice, { isLoading, error }] = useAddInvoice()

  const onSubmit = (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    addInvoice(
      values,
      // @ts-expect-error
      ({ data: { id } }) => {
        const newState = {
          amount: values.amount,
          invoiceId: id,
        }

        setInvoice(newState)
        analytics.account.finance.addFunds.formInvoice.complete(newState)
      },
      (error: APIErrorDeprecated) => {
        setErrors(error.fieldErrors)
        analytics.account.finance.addFunds.formInvoice.error(error, {
          amount: values.amount,
        })
      }
    )
  }

  const [getPdf, { isLoading: isLoadingPdf, error: errorPdf }] = useGetPdf(
    invoice.invoiceId || ''
  )

  useEffect(() => {
    if (!invoice.invoiceId) {
      return
    }

    getPdf(
      // @ts-expect-error
      (data) => {
        downloadPdf(data, 'invoice')
        setInvoice(emptyInvoice)
        analytics.account.finance.addFunds.invoiceShare.complete(invoice)
      },
      (error: APIErrorDeprecated) => {
        setInvoice(emptyInvoice)
        analytics.account.finance.addFunds.invoiceShare.error(error, invoice)
      }
    )
  }, [getPdf, invoice])

  const {
    balance: { amount: balanceAmount },
  } = useBalanceContext()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      renderHeader={() => (
        <Header>
          <H3>Пополните баланс</H3>
        </Header>
      )}
      footer={<div />}
    >
      <Formik<FormValues>
        initialValues={INITIAL_VALUES}
        validationSchema={validation}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, getFieldMeta }) => {
          const amount = getFieldMeta<string>('amount').value

          const increaseAmount = (val: number) => {
            const num: number = makeIntegerFromString(amount)

            setFieldValue('amount', num + val)
            analytics.account.finance.addFunds.chips.click(val, val)
          }

          const setAmount = (val: number) => {
            setFieldValue('amount', val)
            analytics.account.finance.addFunds.chips.click('pending', val)
          }

          return (
            <FormikFormStyled>
              <FormikEffect<FormValues>
                onChange={(current, prev) => {
                  handleGetErrors<FormValues>(
                    current,
                    prev,
                    error,
                    (clientError) => {
                      analytics.account.finance.addFunds.formInvoice.error(
                        clientError,
                        { amount }
                      )
                    }
                  )
                }}
              />

              {balanceAmount < 0 ? (
                <Spacer mb={30} display="flex">
                  <Alert type="warning">
                    У вас имеется задолженность{' '}
                    <Currency value={Math.abs(balanceAmount)} />
                  </Alert>
                </Spacer>
              ) : null}

              <Grid align="center" gutter={2}>
                <Col xs={3}>
                  <FormInput
                    label="Сумма ₽"
                    name="amount"
                    placeholder="Сумма ₽"
                  />
                </Col>

                {balanceAmount < 0 ? (
                  <Col xs="auto">
                    <FormWrap name="btn-debt" label="&nbsp;">
                      <Button
                        type="button"
                        shape="round"
                        onClick={() => setAmount(Math.abs(balanceAmount))}
                      >
                        +
                        <Currency value={Math.abs(balanceAmount)} />
                      </Button>
                    </FormWrap>
                  </Col>
                ) : null}

                <Col xs="auto">
                  <FormWrap name="btn-10" label="&nbsp;">
                    <Button
                      type="button"
                      shape="round"
                      onClick={() => increaseAmount(10000)}
                    >
                      +<Currency value={10000} />
                    </Button>
                  </FormWrap>
                </Col>

                <Col xs="auto">
                  <FormWrap name="btn-50" label="&nbsp;">
                    <Button
                      type="button"
                      shape="round"
                      onClick={() => increaseAmount(50000)}
                    >
                      +<Currency value={50000} />
                    </Button>
                  </FormWrap>
                </Col>

                <Col xs="auto">
                  <FormWrap name="btn-100" label="&nbsp;">
                    <Button
                      type="button"
                      shape="round"
                      onClick={() => increaseAmount(100000)}
                    >
                      +<Currency value={100000} />
                    </Button>
                  </FormWrap>
                </Col>
              </Grid>

              <Spacer mb={36} />

              <FormError error={error || errorPdf} />

              <Button
                type="submit"
                variant="primary"
                size="large"
                isLoading={isLoading || isLoadingPdf}
              >
                Пополнить
              </Button>
            </FormikFormStyled>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalAddBalance
