import styled, { css } from 'styled-components/macro'
import { Props } from './index'

export const StyledSpacer = styled.div<Props>`
  display: ${({ display }) => display};

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ p }) =>
    Number.isFinite(p) &&
    css`
      padding: ${p}px;
    `}

  ${({ px }) =>
    Number.isFinite(px) &&
    css`
      padding-left: ${px}px;
      padding-right: ${px}px;
    `}

  ${({ py }) =>
    Number.isFinite(py) &&
    css`
      padding-top: ${py}px;
      padding-bottom: ${py}px;
    `}

  ${({ pt }) =>
    Number.isFinite(pt) &&
    css`
      padding-top: ${pt}px;
    `}

  ${({ pb }) =>
    Number.isFinite(pb) &&
    css`
      padding-bottom: ${pb}px;
    `}

  ${({ pl }) =>
    Number.isFinite(pl) &&
    css`
      padding-left: ${pl}px;
    `}

  ${({ pr }) =>
    Number.isFinite(pr) &&
    css`
      padding-right: ${pr}px;
    `}

  ${({ m }) =>
    Number.isFinite(m) &&
    css`
      margin: ${m}px;
    `}

  ${({ mx }) =>
    Number.isFinite(mx) &&
    css`
      margin-left: ${mx}px;
      margin-right: ${mx}px;
    `}

  ${({ my }) =>
    Number.isFinite(my) &&
    css`
      margin-top: ${my}px;
      margin-bottom: ${my}px;
    `}

  ${({ mt }) =>
    Number.isFinite(mt) &&
    css`
      margin-top: ${mt}px;
    `}

  ${({ mb }) =>
    Number.isFinite(mb) &&
    css`
      margin-bottom: ${mb}px;
    `}

  ${({ ml }) =>
    Number.isFinite(ml) &&
    css`
      margin-left: ${ml}px;
    `}

  ${({ mr }) =>
    Number.isFinite(mr) &&
    css`
      margin-right: ${mr}px;
    `}
`
