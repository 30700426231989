import { ComponentType, FunctionComponent } from 'react'
import { Container } from 'unstated-next'

export { createContainer } from 'unstated-next'

export const withContainerProvider = <Props extends {}, Value, State = void>(
  WrappedComponent: ComponentType<Props>,
  { Provider }: Container<Value, State>,
  initialState?: State
) => {
  const ComponentWithContainerProvider: FunctionComponent<Props> = (props) => {
    return (
      <Provider initialState={initialState}>
        <WrappedComponent {...props} />
      </Provider>
    )
  }

  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  ComponentWithContainerProvider.displayName = `withContainerProvider(${displayName})`

  return ComponentWithContainerProvider
}
