import sendData from '../../utils/sendData'

type Tab = 'drafts' | 'forthcoming' | 'past' | 'refunded' | 'failed'

type Data = {
  tab: Tab
}

export const click = (tab: Tab) => {
  const data: Data = { tab }

  sendData('order_history.scroll_tab.click', data)
}
