import styled, { css } from 'styled-components/macro'
import Col from './Col'

export type Props = {
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  align: 'flex-start' | 'baseline' | 'center' | 'flex-end' | 'normal'
  justify:
    | 'flex-start'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'flex-end'
  noWrap: boolean
  gutter: number | [number, number]
  divider: boolean
}

const MULTIPLIER = 4

const Wrapper = styled.div<Props>`
  display: flex;

  ${({ theme, direction, align, justify, noWrap, gutter, divider }) => {
    const gutterX = Array.isArray(gutter) ? gutter[0] : gutter
    const gutterY = Array.isArray(gutter) ? gutter[1] : 0

    const totalGutterX = gutterX * MULTIPLIER
    const totalGutterY = gutterY * MULTIPLIER

    return css`
      flex-direction: ${direction};
      align-items: ${align};
      justify-content: ${justify};
      flex-wrap: ${noWrap ? 'nowrap' : 'wrap'};
      margin: -${totalGutterY}px -${totalGutterX}px;
      width: calc(100% + ${totalGutterX * 2}px);

      > ${Col} {
        padding: ${totalGutterY}px ${totalGutterX}px;

        ${divider &&
        css`
          position: relative;

          &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1px;
            background: ${theme.color.textLight};
          }
        `}
      }
    `
  }}
`

export default Wrapper
