import styled, { css } from 'styled-components/macro'

export const FormRow = styled.div<{ withButton?: boolean }>`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ withButton }) =>
    withButton &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 30px;
    `}
`
