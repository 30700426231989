import { SearchData } from 'types/searchData'
import sendData from '../../utils/sendData'
import sendError from '../../utils/sendError'
import transformFlightDetails from '../../utils/transformFlightDetails'

export const complete = (searchData: SearchData) => {
  sendData('serp_avia.search_form.complete', transformFlightDetails(searchData))
}

export const error = (error: string, searchData: SearchData) => {
  sendError(
    'serp_avia.search_form.error',
    error,
    transformFlightDetails(searchData)
  )
}
