import { useState, useEffect, useCallback, useRef } from 'react'
import axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import humps from 'humps'
import Auth from 'contexts/auth'
import {
  isUnauthorizedError,
  APIErrorDeprecated,
  transformError,
} from './errors'

/**
 * @deprecated don't use in components directly, create custom hooks for each endpoint instead
 */
const useAPIDeprecated = ({
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  method,
  // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
  endpoint,
  payload = null,
  params = null,
  isPdf = false,
  cancelable = true,
} = {}) => {
  const { auth, signOut } = Auth.useContainer()
  const [response, setResponse] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<APIErrorDeprecated | string | null>()

  const cancelSourceRef = useRef<CancelTokenSource | null>()

  const callAPI = useCallback(
    async (options = {}, onSuccess, onError, onFinally) => {
      setIsLoading(true)
      setError(null)

      const {
        method: localMethod = method,
        endpoint: localEndpoint = endpoint,
        payload: localPayload = payload,
        params: localParams = params,
        cancelable: localCancelable = cancelable,
      } = options

      if (cancelable && cancelSourceRef.current) {
        cancelSourceRef.current.cancel()
      }

      // @ts-expect-error ts-migrate(2693) FIXME: 'CancelToken' only refers to a type, but is being ... Remove this comment to see the full error message
      const source = CancelToken.source()
      cancelSourceRef.current = localCancelable ? source : null

      try {
        const axiosParams: AxiosRequestConfig = {
          method: localMethod,
          baseURL: B2B_APP_CONFIG.API_URL,
          url: localEndpoint,
          cancelToken: source.token,
          transformRequest: [
            (data) => humps.decamelizeKeys(data),
            // @ts-expect-error ts-migrate(2569) FIXME: Type 'AxiosTransformer | AxiosTransformer[] | unde... Remove this comment to see the full error message
            ...axios.defaults.transformRequest,
          ],
        }

        if (localPayload) {
          axiosParams.data = localPayload
        }

        if (localParams) {
          axiosParams.params = humps.decamelizeKeys(localParams)
        }

        if (auth) {
          axiosParams.headers = { Authorization: `Bearer ${auth.token}` }
        }

        if (isPdf) {
          axiosParams.responseType = 'blob'
          axiosParams.headers = {
            ...axiosParams.headers,
            Accept: 'application/pdf',
          }
        } else {
          axiosParams.transformResponse = [
            // @ts-expect-error ts-migrate(2569) FIXME: Type 'AxiosTransformer | AxiosTransformer[] | unde... Remove this comment to see the full error message
            ...axios.defaults.transformResponse,
            (data) => humps.camelizeKeys(data),
          ]
        }

        const { data } = await axios(axiosParams)

        setResponse(data)
        setIsLoading(false)

        onSuccess && onSuccess(data, setError)
      } catch (err) {
        if (axios.isCancel(err)) {
          // eslint-disable-next-line no-console
          console.log('Request was canceled due to component unmount')
        } else {
          const apiError = transformError(err)
          setError(apiError)
          setIsLoading(false)
          setResponse(null)

          onError && onError(apiError)

          if (isUnauthorizedError(apiError)) {
            signOut()
          }
        }
      } finally {
        onFinally && onFinally()
      }
    },
    [method, endpoint, payload, params, cancelable, auth, signOut, isPdf]
  )

  useEffect(() => {
    return () => {
      if (cancelSourceRef.current) {
        cancelSourceRef.current.cancel()
        cancelSourceRef.current = null
      }
    }
  }, [cancelSourceRef])

  return { response, isLoading, error, setError, callAPI }
}

export default useAPIDeprecated

// Временные функции-обёртки для callAPI. Нужны чтобы:
// 1) Давать IDE инфу об аргументах функции - есть ли у запроса payload/query params или есть только коллбэки onSuccess, onError, onFinally
// 2) Ограничивать опции, передаваемые в callAPI (method, endpoint и т.д. уже заданы в кастомном хуке)
// После переезда на TS и вариант useAPI из админки, будут не нужны
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'callAPI' implicitly has an 'any' type.
export const useCallAPIWithoutParams = (callAPI) => {
  return useCallback(
    (onSuccess?, onError?, onFinally?) => {
      return callAPI({}, onSuccess, onError, onFinally)
    },
    [callAPI]
  )
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'callAPI' implicitly has an 'any' type.
export const useCallAPIWithParams = (callAPI) => {
  return useCallback(
    (params, onSuccess?, onError?, onFinally?) => {
      return callAPI({ params }, onSuccess, onError, onFinally)
    },
    [callAPI]
  )
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'callAPI' implicitly has an 'any' type.
export const useCallAPIWithPayload = (callAPI) => {
  return useCallback(
    (payload, onSuccess?, onError?, onFinally?) => {
      return callAPI({ payload }, onSuccess, onError, onFinally)
    },
    [callAPI]
  )
}
