import { FunctionComponent } from 'react'
import Icon, { Props as IconProps } from 'components/Icon'
import { AlertWrap, IconWrap, TextWrap } from './styled'

export type Types = 'info' | 'warning' | 'error'

type Props = {
  type?: Types
}

const ICONS: Record<Types, string> = {
  info: 'info',
  warning: 'exclamation',
  error: 'warning',
}

const COLORS: Record<Types, IconProps['fill']> = {
  info: 'primaryDarkest',
  warning: 'secondary',
  error: 'fail',
}

const Alert: FunctionComponent<Props> = ({ type = 'error', children }) => {
  return (
    <AlertWrap type={type}>
      <IconWrap>
        <Icon name={ICONS[type]} fill={COLORS[type]} size="xsmall" />
      </IconWrap>

      <TextWrap>
        <div>{children}</div>
      </TextWrap>
    </AlertWrap>
  )
}

export default Alert
