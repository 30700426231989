import { APIErrorDeprecated } from 'api/client/errors'
import sendData from '../../../utils/sendData'
import sendError from '../../../utils/sendError'

export const complete = () => {
  sendData('account.logout.complete')
}

export const error = (error: APIErrorDeprecated | string) => {
  sendError('account.logout.error', error)
}
