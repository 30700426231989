const getAddedAndChangedFields = <T extends Record<string, any>>(
  item: T,
  initialItem: T
): {
  fieldsAdded: (keyof T)[]
  fieldsChanged: (keyof T)[]
} => {
  const fieldsAdded = Object.keys(item)
    .filter((key) => !initialItem[key])
    .map((key) => key)

  const fieldsChanged = Object.entries(initialItem)
    .filter(([key, value]) => value !== item[key])
    .map(([key]) => key)

  return { fieldsAdded, fieldsChanged }
}

export default getAddedAndChangedFields
