import styled, { css } from 'styled-components/macro'
import media from 'utils/media'

export const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.miscLightest};
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;

  ${media.up.lg(css`
    display: none;
  `)}
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 18px 18px;
  max-width: 500px;
  margin: auto;
`
