import { Upsell } from 'types/api/ticket'
import { Order } from 'types/order'
import { APIErrorDeprecated } from 'api/client/errors'
import {
  FlightDetails,
  transformFlightDetailsFromTicket,
} from '../../../utils/transformFlightDetails'
import transformTicketDetails, {
  TicketDetails,
} from '../../../utils/transformTicketDetails'
import transformAddFareDetails, {
  AddFareDetails,
} from '../../../utils/transformAddFareDetails'
import sendError from '../../../utils/sendError'
import * as pageLoad from './pageLoad'
import * as changing from './changing'
import * as deletion from './deletion'
import * as issuing from './issuing'
import * as accountingDocs from './accountingDocs'
import * as passengerDocs from './passengerDocs'

export { pageLoad, changing, deletion, issuing, accountingDocs, passengerDocs }

type Data = Partial<FlightDetails & TicketDetails & AddFareDetails> & {
  orderId: string
}

export const error = (
  error: APIErrorDeprecated,
  orderId: string,
  order: Order | null,
  selectedUpsell: Upsell | null
) => {
  const data: Data = order
    ? {
        orderId,
        ...transformFlightDetailsFromTicket(
          order.flight,
          order.passengers.length
        ),
        ...transformTicketDetails(order.flight, selectedUpsell),
        ...transformAddFareDetails(order.flight, selectedUpsell),
      }
    : { orderId }

  sendError('order_history.order.error', error, data)
}
