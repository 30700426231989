import { useEffect } from 'react'
import { useBalanceContext } from 'contexts/BalanceProvider'
import Spacer from 'components/Spacer'
import ButtonAddBalance from 'components/ButtonAddBalance'
import { Wrap } from './styled'
import Amount from './Amount'

const REQUEST_INTERVAL: number = 30000

const Balance = () => {
  const { updateBalance } = useBalanceContext()

  useEffect(() => {
    updateBalance()

    const interval = setInterval(updateBalance, REQUEST_INTERVAL)

    return () => clearInterval(interval)
  }, [updateBalance])

  return (
    <Wrap>
      <Spacer mr={16} display="inline-block">
        <Amount />
      </Spacer>

      <ButtonAddBalance
        icon="plus"
        source="header"
        shape="round"
        size="large"
      />
    </Wrap>
  )
}

export default Balance
