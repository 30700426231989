import { SearchData } from 'types/searchData'
import sendData from '../../../utils/sendData'
import transformFlightDetails from '../../../utils/transformFlightDetails'

export const complete = (searchData: SearchData) => {
  sendData(
    'serp_avia.ticket.selection.complete',
    transformFlightDetails(searchData)
  )
}
