import styled from 'styled-components/macro'

export const LabelWrap = styled.div`
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;

  & > * {
    font-size: 14px;
    line-height: 18px;
  }
`

export const DescriptionWrap = styled.div`
  padding-top: 6px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.text};
`
