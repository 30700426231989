import { decamelize } from 'humps'
import sendData from '../../../utils/sendData'
import * as pageLoad from './pageLoad'
import * as email from './email'
import * as changing from './changing'

export { pageLoad, email, changing }

type Values = {
  postalAddress: string
}

type Data = {
  fieldsChanged: (keyof Values)[]
}

export const changed = (values: Values) => {
  const data: Data = {
    fieldsChanged: Object.keys(values) as (keyof Values)[],
  }

  sendData('account.company_settings.changed', {
    fieldsChanged: data.fieldsChanged.map((v) => decamelize(v)),
  })
}
