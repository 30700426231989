import useAPIDeprecated, {
  useCallAPIWithoutParams,
  useCallAPIWithPayload,
} from './client/useAPIDeprecated'

export type CompanyBranch = {
  active: boolean
  name: string
  address: string
  inn: string
  kpp?: string
  manager?: string
}

export type CompanyFromInnResponse = {
  branches: CompanyBranch[]
}

export const useGetCompanyFromInn = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/companies/data',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export type ValidateUserRequest = {
  companyName: string
  inn: string
  kpp: string
  manager: string
  businessAddress: string
  postalAddress: string
}

export const useValidateUser = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/users/validate',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export type SignUpRequest = ValidateUserRequest & {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  signer: {
    firstName: string
    lastName: string
    middleName: string
    position: string
  }
}

export type SignUpResponse = {
  id: string // user id
  companyId: string
}

export const useSignUp = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/users',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export const useSignIn = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/auth',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export const useSignOut = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'delete',
    endpoint: '/auth',
    cancelable: false,
  })
  return [useCallAPIWithoutParams(callAPI), states] as const
}

export const useResetPassword = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/password/reset',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}

export const useConfirmPassword = () => {
  const { callAPI, ...states } = useAPIDeprecated({
    method: 'post',
    endpoint: '/password/confirm',
  })
  return [useCallAPIWithPayload(callAPI), states] as const
}
