import { addMethod, string } from 'yup'
import moment from 'moment'
import { trimFull } from './trimFull'

addMethod(
  string,
  'number',
  function (message = 'Поле должно содержать только цифры') {
    return string().matches(/^\d+$/, message)
  }
)

export const REQUIRED = string().required('Обязательное поле')

export const REQUIRED_NUM = string().required('Обязательное поле').number()

export const EMAIL = string()
  .required('Введите почту')
  .email('Неверный формат email')

export const PASSWORD = string().required('Введите пароль')

export const INN = string()
  .required('Обязательное поле')
  .matches(/^\d{10}$|^\d{12}$/, 'ИНН введен некорректно')

export const KPP = string()
  .required('Обязательное поле')
  .number()
  .length(9, 'КПП введен некорректно')

export const FULL_NAME = string()
  .required('Обязательное поле')
  .transform(trimFull)
  .matches(
    /^[a-zA-Zа-яёА-ЯЁ'\-.]+\s[a-zA-Zа-яёА-ЯЁ'\-.]+$/,
    'Поле должно содержать имя и фамилию'
  )

export const FULL_NAME_WITH_MIDDLE = string()
  .required('Обязательное поле')
  .transform(trimFull)
  .matches(
    /^[a-zA-Zа-яёА-ЯЁ'\-.]+\s[a-zA-Zа-яёА-ЯЁ'\-.]+(\s[a-zA-Zа-яёА-ЯЁ'\-.\s]+)?$/,
    'Поле должно содержать фамилию, имя и отчество'
  )

export const BIRTHDATE = string()
  .required('Обязательное поле')
  .test('Is valid date', 'Неправильная дата', (value) =>
    moment(value).isValid()
  )
