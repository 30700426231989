import { useState, FunctionComponent } from 'react'
import useDebounceEffect from 'utils/hooks/useDebounceEffect'
import { H6 } from 'components/typography'
import Icon from 'components/Icon'
import { CenterWrapper } from 'components/CenterWrapper/styled'
import Spacer from 'components/Spacer'

type Props = {
  text?: string
  disableText?: boolean
  className?: string
}

const Loading: FunctionComponent<Props> = ({
  text = 'Загрузка',
  disableText,
  className,
}) => {
  const [show, setShow] = useState<boolean>(false)

  useDebounceEffect(() => setShow(true), [], 300)

  return show ? (
    <CenterWrapper className={className}>
      <Icon name="spinner" fill="miscDark" size="xxlarge" />

      {!disableText && (
        <Spacer mt={6}>
          <H6>{text}…</H6>
        </Spacer>
      )}
    </CenterWrapper>
  ) : null
}

export default Loading
